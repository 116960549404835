
export default {
    name: 'CaseLog',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            reason: '',
            remark: '',
            risk: '',
            txtHoverStyleObj: {
                flag0: false,
                flag1: false,
                flag2: false
            }
        };
    },
    mounted() {},
    methods: {
        //获取案件核赔信息
        getAdjust() {
            this.instance.get('/tpa/api/case/enter/adjust/getAdjust?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.risk = data.data.risk;
                    this.reason = data.data.reason;
                    this.remark = data.data.remark;
                    this.$forceUpdate();
                }
            });
        },
        setTxtOverFlowStyle() {
            let ellipsisEls = document.querySelectorAll('.ellipsisCaseLoss');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        caseDetail(data) {
            if (data.caseNo) {
                this.getAdjust();
            }
        },
        elementShow: {
            handler(val) {
                if (val) {
                    this.setTxtOverFlowStyle();
                }
            },
            immediate: true
        }
    }
};
