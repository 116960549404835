
import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
import Claimant from './Claimant.vue';
import ReportCase from './ReportCase.vue';
import CaseDetail from './CaseInfo.vue';
import CaseRisk from './CaseRisk.vue';
import GuaranteeSlip from './GuaranteeSlip.vue';
import Waybill from './Waybill.vue';
import CaseLog from './CaseLog.vue';
import JdPay from './JdPay.vue';
import LossAssessment from './LossAssessment.vue';
import CaseStep from './CaseStep.vue';
import SuspendNav from './suspendNav.vue';
import SurveyCase from './surveyCase.vue';
export default {
    name: 'myTask',
    props: {
        handleData: {
            type: Object,
            default: {}
        }
    },
    components: {
        ImgDeal,
        Claimant,
        ReportCase,
        CaseDetail,
        CaseRisk,
        GuaranteeSlip,
        Waybill,
        CaseLog,
        JdPay,
        LossAssessment,
        CaseStep,
        SuspendNav,
        SurveyCase
    },
    data() {
        return {
            closeCaseFlag: false,
            actionShow: true,
            overBtnShow: false,
            scrollFlag: false,
            hideCaseLog: false,
            caseDetail: {
                caseRiskVos: []
            },
            fileTypeList: [],
            allPriceVisible: false,
            drawer: false,
            caseLoading: false,
            checkedCase: {},
            suspensionBtnObj: {
                surveyEdit: null,
                sort: 1,
                step: '立案',
                tmpStep: {}
            },
            submitDialogVisible: false,
            submitRuleForm: {
                action: 'APPROVE',
                reason: [],
                remark: '',
                supplyFileType: []
            },
            submitRules: {
                supplyFileType: [{ required: true, message: '请选择类型', trigger: 'change' }],
                reason: [{ required: true, message: '请选择原因', trigger: ['change', 'blur'] }]
            },
            radioGroupList: [
                {
                    value: 'APPROVE',
                    label: '通过'
                },
                {
                    value: 'REJECT',
                    label: '不通过'
                }
            ],
            closeRadioGroup: [
                {
                    value: 'APPROVE_ZERO',
                    label: '0赔'
                },
                {
                    value: 'CANCEL',
                    label: '注销'
                }
            ],
            reasonOptionList: [],
            caseMaterialsType: [], //材料类型
            reasonOption: [],
            isBtnShow: false,
            submitDisabled: false,
            closeCaseSubitDisabled: false,
            tmpOSSClient: null,
            retractFlag: false,
            disappearFlag: false,
            closeCaseDisappear: false,
            detailHeight: '300px',
            goBackOption: [],
            suspensionList: [{ name: '索赔信息' }, { name: '案件风险' }, { name: '运单信息' }, { name: '谈赔信息' }, { name: '案件定损' }, { name: '报案信息' }, { name: '案件信息' }, { name: '保单信息' }, { name: '案件日志' }],
            detailsRadio: '0',
            detailsRadioArr: [
                {
                    label: '案件定损'
                },
                {
                    label: '京东谈赔信息'
                },
                {
                    label: '案件信息'
                },
                {
                    label: '保单信息'
                },
                {
                    label: '运单信息'
                }
            ],
            surveyEdit: false,
            surveyDialog: false,
            surveyForm: {
                planSurveyTime: '',
                surveyPersonType: '',
                surveyPersonName: '',
                surveyPersonPhone: '',
                surveyPersonIdCard: ''
            },
            surveyRules: {
                planSurveyTime: [{ required: true, message: '请选择计划查勘时间', trigger: ['blur', 'change'] }],
                surveyPersonType: [{ required: true, message: '请选择查勘人员身份类型', trigger: 'blur' }],
                surveyPersonName: [{ required: true, message: '请填写联系人姓名', trigger: 'blur' }],
                surveyPersonPhone: [{ required: true, message: '请填写联系人手机号', trigger: 'blur' }],
                surveyPersonIdCard: [{ required: true, message: '请填写联系人身份证', trigger: 'blur' }]
            },
            surveyTypeOptions: [],
            surveyCostInfo: [],
            auditReasonEnum: [],
            suggestReasonScript: [],
            adjustedTotalAmount: null,
            closeCaseReason: [],
            caseCancelReasonEnum: [],
            caseZeroClaimEnum: [],
            closeCaseForm: {
                action: 'APPROVE_ZERO',
                reason: [],
                remark: '',
                supplyFileType: []
            },
            resetOssNum: 0
        };
    },
    mounted() {
        this.initOSSClient();
        window.onresize = () => {
            this.calcHeight();
        };

        this.isBtnShow = this.handleData.detailShow != 'detailShow';

        // 绑定卡片tab 与 卡片 关联关系
        this.detailsRadioArr.forEach((item, index) => {
            item.code = index;
        });

        // 获取其他费用选项
        this.getOtherSelect();
        // 获取损失程度
        this.getDamagedDegreeEnum();
        // 获取材料类型
        this.getFileTypeList();
        // 获取审核原因
        // this.getReason()
        // 获取退回理赔原因
        this.getGoBackLossDiffEnum();
    },
    methods: {
        // 设置定损卡片是否可编辑
        caseCardParentVariable(data) {
            // clickType survey - 发起查勘 handle - 定损处理
            if (this.handleData.clickType == 'survey' || this.caseDetail.isSceneSurvey) {
                this[data.variableStr] = data.flag;
                if (data.variableStr == 'surveyEdit') {
                    this.$refs.lossAssessmentRef.setisEditInfo(this.surveyEdit);
                }
            }
        },
        surveyBtn(btnStr) {
            switch (btnStr) {
                case 'edit':
                    // 显示子组件，反馈查看信息弹窗
                    this.$refs.surveyCase.changeDialogFlag('surveyDialog', true);

                    break;
                case 'over':
                    // 传递查勘信息先校验必填项
                    this.$refs.surveyCase.submitFeeBack(false);
                    break;
                case 'log':
                    // 更新案件日志
                    this.handleCaseList(this.caseDetail.caseNo);
                    break;
                case 'action':
                    // 获取查勘费用后，展示提交按钮
                    this.getSurveyCost(this.caseDetail.caseNo);
                    break;

                default:
                    // 查看信息校验通过，弹窗确认再提交查勘实际日期等
                    this.$confirm('查勘结束将会流转案件, 是否继续?', null, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            this.$refs.surveyCase.submitFeeBack(true);
                        })
                        .catch(() => {});
                    break;
            }
        },
        handleScroll(e) {
            // this.scrollFlag = e.target.scrollTop >= 130;
        },
        getGoBackLossDiffEnum() {
            this.instance.post('/tpa/api/enum/getGoBackLossDiffEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.reasonOption = data.data;
                }
            });
        },
        hiddenCaseLog() {
            this.hideCaseLog = !this.hideCaseLog;
        },
        saveForm() {
            localStorage.setItem(String(this.caseDetail.caseNo), JSON.stringify(this.submitRuleForm));
        },
        calcHeight() {
            let headerHeight = document.querySelector('.header') ? document.querySelector('.header').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let caseStepHeight = document.querySelector('.case-step') ? document.querySelector('.case-step').clientHeight : 0;
            let guHeight = 55 - 5 + 65;
            this.detailHeight = window.innerHeight - headerHeight - registerTabsHeight - caseStepHeight - guHeight + 'px';
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        closeCaseDetail(data) {
            this.$emit('closeCaseDetail', data);
        },
        handleRadioGroup(data) {
            this.suspensionBtnObj = data ? data : {};
            // tmpStep.code == 50
            // tmpStep.nodeDesc == 50
            if (data && data.step == '核赔') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    }
                ];
            }

            if (data && data.step == '定损') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    },
                    {
                        value: 'REJECT',
                        label: '退回'
                    }
                ];
            }
        },
        // 查勘费用
        getSurveyCost(caseNo) {
            // 设置提交按钮隐藏
            this.actionShow = false;
            this.instance.post(`/tpa/api/case/survey/fee/get/${caseNo}`).then((res) => {
                // 展示提交按钮
                this.actionShow = true;
                let data = res.data;
                if (data.code == 200) {
                    this.surveyCostInfo = data.data;
                }
            });
        },
        // 案件详情
        handleCaseList(caseNo) {
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            this.caseDetail = {};
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        this.initCaseDetail(res.data.data);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            var _this = this;
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.submitRuleForm.caseNo = response.caseNo;
            this.queryCaseNo = response.caseNo;

            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
            }

            let isTabStr = false;
            this.detailsRadioArr.forEach((item) => {
                if (item.label == '查勘信息') {
                    isTabStr = true;
                }
            });
            // 是否需要现场勘查，展示查勘信息tab
            if (this.caseDetail.isSceneSurvey && !isTabStr) {
                this.detailsRadioArr.unshift({ label: '查勘信息' });
            }
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        editOtherList(index, btnStr) {
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: ''
                });
            }

            if (this.goodsModeForm.otherExpenses.length > 15) {
                this.$message.error('其他费用，目前仅支持添加15个费用');
            }
        },
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        //获取受损程度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        // 提交案件按钮
        submitCase(submitFlag) {
            let _this = this;
            // 查勘案件需要先判断查勘费用是否填写
            if (this.caseDetail.isSceneSurvey) {
                if (this.surveyCostInfo.length == 0) {
                    this.$message.error('公估费，查勘费，律师费，不能全都为空');
                    return;
                }

                let tmpType = null;
                if (this.caseDetail.materialsList) {
                    this.caseDetail.materialsList.forEach((item) => {
                        if (item.materialType == 9902) {
                            tmpType = item.materialType;
                        }
                    });
                }

                if (!tmpType) {
                    this.$message.error('请上传查勘报告');
                    return;
                }
            }
            // end

            if (localStorage.getItem('editConfirmAmount') == 'true') {
                this.$message.error('点击了修改 “赔偿确认金额” ，但还未提交');
                return;
            }

            if (!submitFlag) {
                this.submitDialogVisible = true;
                if (localStorage.getItem(String(this.caseDetail.caseNo))) {
                    this.submitRuleForm = JSON.parse(localStorage.getItem(String(this.caseDetail.caseNo)));
                }
            } else {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid && !this.disappearFlag) {
                        this.submitRuleForm.reason = Array.isArray(this.submitRuleForm.reason) ? this.submitRuleForm.reason : [this.submitRuleForm.reason];
                        var json = this.submitRuleForm;
                        json.caseNo = this.caseDetail.caseNo;
                        this.submitDisabled = true;
                        this.disappearFlag = true;

                        this.instance.post('/tpa/api/case/action', json).then((res) => {
                            this.submitDisabled = false;
                            if (res.data.code == 200) {
                                this.recordEventTracking(this.caseDetail.caseNo, this.caseDetail.caseStep, '案件当前阶段处理完成');
                                localStorage.removeItem(String(this.caseDetail.caseNo));
                                this.$message.success('提交成功');
                                setTimeout(() => {
                                    this.submitDialogVisible = false;
                                    this.$refs.ruleForm.resetFields();
                                    this.$emit('closeCaseDetail');
                                    this.disappearFlag = false;
                                }, 500);
                                this.handleCaseList(this.handleData.caseNo);
                            } else {
                                this.disappearFlag = false;
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            }
        },
        submitCloseCase(submitFlag) {
            if (submitFlag) {
                this.$refs.closeCaseRef.validate((valid) => {
                    if (valid && !this.closeCaseDisappear) {
                        this.closeCaseForm.reason = Array.isArray(this.closeCaseForm.reason) ? this.closeCaseForm.reason : [this.closeCaseForm.reason];
                        var json = this.closeCaseForm;
                        json.caseNo = this.caseDetail.caseNo;
                        // 节流-重复点击
                        this.closeCaseSubitDisabled = true;
                        this.closeCaseDisappear = true;

                        this.instance.post('/tpa/api/case/zeroClaimOrCancel', json).then((res) => {
                            this.closeCaseSubitDisabled = false;
                            if (res.data.code == 200) {
                                this.recordEventTracking(this.caseDetail.caseNo, this.caseDetail.caseStep, '案件当前阶段处理完成');
                                localStorage.removeItem(String(this.caseDetail.caseNo));
                                this.$message.success('提交成功');
                                setTimeout(() => {
                                    this.submitDialogVisible = false;
                                    this.$refs.closeCaseRef.resetFields();
                                    this.$emit('closeCaseDetail');
                                    this.closeCaseDisappear = false;
                                }, 500);
                                this.handleCaseList(this.handleData.caseNo);
                            } else {
                                this.closeCaseDisappear = false;
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            } else {
                this.getCaseCancelReasonEnum();
                this.getCaseZeroClaimEnum();
                this.closeCaseFlag = true;
            }
        },
        // 获取案件注销原因
        getCaseCancelReasonEnum() {
            this.instance.get('/tpa/api/enum/getCaseCancelReasonEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseCancelReasonEnum = data.data;
                }
            });
        },
        // 获取案件0赔付原因
        getCaseZeroClaimEnum() {
            this.instance.get('/tpa/api/enum/getCaseZeroClaimEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseZeroClaimEnum = data.data;
                    this.closeCaseReason = this.caseZeroClaimEnum;
                }
            });
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        // 获取审核原因
        getReason() {
            this.instance.post('/tpa/api/enum/getDict', { dictName: this.handleData.menuTypeNum, dictType: 'ACTION_REASON_DICT' }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.reasonOption = data.data;
                    this.submitRuleForm.reason = this.reasonOption[0] ? this.reasonOption[0].metaData : '';
                }
            });
        },
        radioInput(activeVal, type) {
            if (type == 2) {
                if (activeVal == 'CANCEL') {
                    this.closeCaseReason = this.caseCancelReasonEnum;
                } else {
                    this.closeCaseReason = this.caseZeroClaimEnum;
                }
            } else if (activeVal == 'REJECT') {
                this.submitRuleForm.reason = [];
            }
        },
        closeSubmitDialog(e, saveFlag, type) {
            if (type == 2) {
                this.closeCaseFlag = false;
            } else {
                this.saveForm();
                this.submitDialogVisible = false;
            }
        },
        setSuggestionTxt(index) {
            if (index) {
                let remarkStr = '';
                this.suggestReasonScript.forEach((item) => {
                    remarkStr += item + '\n';
                });
                this.submitRuleForm.remark = remarkStr;
            } else {
                this.auditReasonEnum.forEach((item) => {
                    if (this.submitRuleForm.reason.indexOf(item.desc) == -1) {
                        this.submitRuleForm.reason.push(item.desc);
                    }
                });
                this.$refs.ruleForm.validate();
            }
        },
        // 减赔原因/建议话术/审核原因
        getSuggestPredict(caseNo) {
            this.auditReasonEnum = [];
            this.suggestReasonScript = [];
            this.instance.get('/tpa/api/case/negotiate/suggest/predict/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    data.data.forEach((item, index) => {
                        // 审核原因
                        if (item.auditReasonEnum && !this.auditReasonEnum.some((obj) => obj.code === item.auditReasonEnum.code)) {
                            this.auditReasonEnum.push(item.auditReasonEnum);
                        }

                        // 建议话术
                        if (item.suggestReasonScript != '无' && item.suggestReasonScript) {
                            this.suggestReasonScript.push(item.suggestReasonScript);
                        }
                    });

                    // 增加序号
                    for (let i = 0; i < this.suggestReasonScript.length; i++) {
                        this.suggestReasonScript[i] = i + 1 + '、' + this.suggestReasonScript[i];
                    }
                } else {
                    this.$message.error(data.message);
                }
                this.getReviewReason(caseNo);
            });
        },
        // 复核原因
        getReviewReason(caseNo) {
            this.instance.get('/tpa/api/case/adjust/review/loss/reason/' + caseNo, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    let reasonData = data.data;
                    // 建议原因回显
                    this.auditReasonEnum = [];
                    reasonData.reason.forEach((reasonItem) => {
                        this.auditReasonEnum.push({
                            desc: reasonItem
                        });
                    });
                    // 建议情况回显
                    this.suggestReasonScript = [];
                    if (reasonData.remark) {
                        let tmpRemark = reasonData.remark.split('\n');
                        tmpRemark.forEach((remarkItem) => {
                            remarkItem && this.suggestReasonScript.push(remarkItem);
                        });
                    }

                    // 非京责-填写回显值
                    this.queryCaseDetail(reasonData);
                }
            });
        },
        queryCaseDetail(reasonData) {
            if (this.caseDetail.caseNo) {
                if (this.caseDetail.damageInfo && !this.caseDetail.damageInfo.jdDuty) {
                    this.submitRuleForm.action = reasonData.action;
                    this.submitRuleForm.reason = reasonData.reason;
                    this.submitRuleForm.remark = reasonData.remark;
                }
                //     this.submitRuleForm.action = 'REJECT';
                //     this.submitRuleForm.reason = ['非保险责任'];
                //     this.submitRuleForm.remark = '案件为“非京责”案件';
            } else {
                setTimeout(() => {
                    this.queryCaseDetail(reasonData);
                }, 500);
            }
        }
    },
    watch: {
        handleData: {
            handler(val) {
                // 获取案件详情
                val.caseNo && this.handleCaseList(this.handleData.caseNo);
                // 查勘费用
                val.caseNo && this.getSurveyCost(this.handleData.caseNo);
                // 减赔原因
                val.caseNo && this.getSuggestPredict(val.caseNo);

                this.calcHeight();
            },
            immediate: true
        }
    }
};
