
export default {
    name: 'GuaranteeSlip',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectOptions: [],
            tmpGoodsCategory: '',
            expandFlag: false,
            value: '',
            textarea: '',
            stipulationTxt: '',
            tmpStipulationTxt: '',
            txtHoverStyleObj: {
                flag0: false,
                flag1: false,
                flag2: false,
                flag3: false,
                flag4: false,
                flag5: false,
                flag6: false
            }
        };
    },
    mounted() {
        this.getAllSpecialAgreement();
    },
    methods: {
        expandBtn() {
            this.expandFlag = !this.expandFlag;
            this.tmpStipulationTxt = this.expandFlag ? this.stipulationTxt : this.stipulationTxt.slice(0, 400);
        },
        getAllSpecialAgreement() {
            this.instance.post('/tpa/api/special/agreement/getAllSpecialAgreement', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.selectOptions = data.data;
                    setTimeout(() => {
                        this.mateValue();
                    }, 500);
                }
            });
        },
        mateValue() {
            let searchFlag = false;
            this.selectOptions &&
                this.selectOptions.forEach((item) => {
                    if (item.goodsCategory == this.tmpGoodsCategory) {
                        searchFlag = true;
                        this.value = item.goodsCategoryCode;
                        this.changeStipulationTxt(this.value);
                    }
                });

            // 当前类别匹配不到特别约定的下拉项
            if (!searchFlag) {
                this.tmpGoodsCategory = '其他';
                this.value = '其他';
                this.changeStipulationTxt(180);
            }
        },
        changeStipulationTxt(itemKey) {
            let tmpIndex = this.selectOptions.findIndex((item, index) => {
                return item.goodsCategoryCode == itemKey;
            });
            if (tmpIndex != -1) {
                this.stipulationTxt = JSON.parse(JSON.stringify(this.selectOptions[tmpIndex].agreement));
                this.stipulationTxt = this.stipulationTxt.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp');
                this.expandBtn();
            }
        },
        setTxtOverFlowStyle() {
            let ellipsisEls = document.querySelectorAll('.ellipsisGuaranteeSlip');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        caseDetail: {
            handler(val) {
                if (val && val.caseNo) {
                    this.tmpGoodsCategory = val.goodsInfo[0].goodsCategory;
                }
            },
            immediate: true
        },
        elementShow: {
            handler(val) {
                if (val) {
                    this.setTxtOverFlowStyle();
                }
            },
            immediate: true
        }
    }
};
