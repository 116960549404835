
export default {
    name: 'myTask',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        suspensionList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            retractFlag: false
        }
    },
    mounted() {},
    methods: {
        handleClick(tabIndex) {
            let elCards = this.$el.parentElement.querySelectorAll('.case_card')
            elCards.forEach((item, index) => {
                if (tabIndex == index) {
                    item.scrollIntoView({
                        alignToTop: false,
                        behavior: 'smooth',
                        block: 'start'
                    })
                }
            })
        }
    }
}
