
export default {
    name: 'CaseStep',
    props: {
        handleData: {
            type: Object,
            default: {}
        },
        caseDetail: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            relateCaseFlag: false,
            stepActive: 1,
            caseFlowStep: [],
            suspensionBtnObj: {
                sort: 1,
                step: '立案',
                tmpStep: {}
            },
            showStep: true,
            relateCaseData: []
        };
    },
    updated() {
        this.$emit('calcHeight');
    },
    methods: {
        //获取案件步骤条
        getCaseStep() {
            this.instance.get('/tpa/api/enum/getCaseStep', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    data.data && this.mateStepData(data.data);
                }
            });
        },
        //获取案件步骤条
        getPreCaseStep() {
            this.instance.get('/tpa/api/enum/getPreCaseStep', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    data.data && this.mateStepData(data.data);
                }
            });
        },
        // 匹配步骤条数据
        mateStepData(caseStep) {
            let stepActive = 0;
            let tmpStep = {};
            let tmpStepLogList = this.caseDetail.stepLogList;
            if (caseStep.length > 0) {
                caseStep.forEach((val, key) => {
                    // 匹配当前案件停留在哪个步骤条
                    val.caseStepList.forEach((value) => {
                        if (value.code == this.caseDetail.caseStep) {
                            stepActive = key + 1;
                            tmpStep = value;
                        }
                    });

                    // 匹配对应步骤处理人和处理时间
                    tmpStepLogList.length > 0 &&
                        tmpStepLogList.forEach((item, index) => {
                            val.caseStepList.forEach((v) => {
                                if (item.caseStep == v.code) {
                                    val.handleTime = item.handleTime;
                                    val.handler = item.handler;
                                }
                            });
                        });
                });

                // 80注销 页面展示结案
                if (this.caseDetail.caseStep == 80) {
                    stepActive = caseStep.length;
                    tmpStep = caseStep[caseStep.length - 1];
                }
                stepActive > 0 && (this.stepActive = stepActive);
            }
            this.caseFlowStep = caseStep;

            // 底部悬浮按钮
            this.suspensionBtnObj = this.caseFlowStep[this.stepActive - 1];
            this.suspensionBtnObj.tmpStep = tmpStep;

            // 提交弹窗审核结果选项
            this.$emit('handleRadioGroup', this.suspensionBtnObj);
        },
        switchCaseInfo(reportBackFlag) {
            this.openCase(this.caseDetail.preReportCaseNo);
            return;
            if (reportBackFlag) {
                this.$emit('closeCaseDetail', {
                    caseNo: this.handleData.primaryCaseNo,
                    menuTypeNum: this.handleData.tabTypeNum,
                    detailStr: this.handleData.tmpHandleData ? this.handleData.tmpHandleData.detailStr : '',
                    detailShow: this.handleData.tmpHandleData ? this.handleData.tmpHandleData.detailShow : ''
                });
            } else {
                this.$emit('closeCaseDetail', { caseNo: this.caseDetail.preReportCaseNo, menuTypeNum: 15, switchFlag: true, tabTypeNum: this.handleData.menuTypeNum, primaryCaseNo: this.caseDetail.caseNo, subscriptShow: true, tmpHandleData: this.handleData });
            }
        },
        switchCaseStep(clickType) {
            this.showStep = clickType;
        },
        relateCaseFn() {
            this.relateCaseData = this.caseDetail.relatedCaseNos;
            this.relateCaseFlag = true;
        },
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        }
    },
    watch: {
        caseDetail: {
            handler(val) {
                if (val && val.caseNo) {
                    if (val.isPreReport) {
                        this.getPreCaseStep();
                    } else {
                        this.getCaseStep();
                    }
                }
            },
            immediate: true
        }
    }
};
