
// @ is an alias to /src
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'technologicalTool',
    data() {
        return {
            resetOssNum: 0,
            loading: false,
            fullscreenLoading: false,
            activeName: 'first',
            beforeUploadData: {},
            baseURL: '',
            url: '',
            unloaderHeader: { token: '' }, //上传的请求头
            idCardFrontUrl: '',
            idCardBackUrl: '',
            type: '',
            desc: '',
            dataList: [],
            uploadFile1: {},
            uploadFile2: {},
            uploadFile2_1: {},
            uploadFile3: {},
            uploadFile4: {},
            uploadFile5: {},
            invoiceUrl: '',
            invoiceCheckMessage: '',
            invoiceDataList: [],

            repeatCaseNo: '',
            repeatImgUrl: [],

            echoImgUrl: '',
            psCaseNo: '',
            psImgUrl: '',
            uploadPsData: {},

            qrCodeDataList: [],

            uploadData: [
                {
                    id: 1,
                    title: '上传材料：',
                    // src:require('../../../../public/images/car-damage/success_icon.png'),
                    remark: '',
                    fileList: []
                }
            ],

            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            navForm: {
                organicCode: ''
            },
            isDisabled: false,
            maotaiResult: {
                certCode: '',
                certAudType: '',
                productCatalogName: '',
                productTypingName: '',
                productDimensionUnit: '',
                markUsePattern: '',
                certAuthorityName: '',
                certOrgName: '',
                certStatus: ''
            },
            pageTitle: '',
            sourceImgUrl: null,
            tmpOSSClient: null,
            resultList: []
        };
    },
    mounted() {
        this.pageTitle = this.$route.meta.title;
        this.baseURL = this.$store.getters.baseURL;

        this.initOSSClient();
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });

                        this.tmpOSSClient.resetOss = true;
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        initResultPath(resultData) {
            resultData.forEach((res) => {
                res.previewPath = res.ossPath ? this.tmpOSSClient.signatureUrl(res.ossPath) : this.tmpOSSClient.signatureUrl(res.materialPath);
            });
        },
        //OSS直传
        ossUpload(e, uploadType) {
            this.resultList = null;
            let _this = this;
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.')[1];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl;
            this.sourceImgUrl = null;
            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            if (!uploadType && fileTypeList.indexOf(tmpSuffix) == -1) {
                this.$message.error('请选择正确文件类型');
                return;
            }
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                setTimeout(() => {
                    client
                        .multipartUpload(storeAs, file)
                        .then(function (result) {
                            // storeAs - 阿里返回图片路径
                            switch (uploadType) {
                                case 'searchSimilarImages':
                                    _this.searchSimilarImages({ ossPath: storeAs });
                                    break;
                                case 'checkDetection':
                                    _this.checkDetection({ ossPath: storeAs });
                                    break;

                                default:
                                    break;
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }, 500);
            });
        },
        searchSimilarImages(searchData) {
            this.instance.post('/tpa/ocrAbility/searchSimilarImages', searchData).then((res) => {
                this.fullscreenLoading.close();
                let data = res.data || null;
                if (data.code == 200 && data.data) {
                    this.sourceImgUrl = this.tmpOSSClient.signatureUrl(searchData.ossPath);
                    this.resultList = data.data;
                    this.initResultPath(this.resultList);
                }
            });
        },
        checkDetection(searchData) {
            this.instance.post('/tpa/ocrAbility/newPSDetectionCheck', searchData).then((res) => {
                this.fullscreenLoading.close();
                let data = res.data || null;
                if (data.code == 200 && data.data) {
                    this.sourceImgUrl = this.tmpOSSClient.signatureUrl(searchData.ossPath);
                    this.resultList = [data.data];
                    this.initResultPath(this.resultList);
                }
            });
        },
        //切换tab
        handleClickTab() {
            this.sourceImgUrl = null;
            this.resultList = [];
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {},
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close();
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }
                return false;
            }
            this.dataList = res.data.data;
            this.type = res.data.typeName;
            this.desc = res.data.desc;
            let url = URL.createObjectURL(file.raw);

            this.uploadFile1 = file;
            this.idCardFrontUrl = file.url;
            // if(res.code==200){
            // 	this.$message.success(res.message);
            // 	// this.caseExcelData=res.data;
            // }else{
            // 	this.$message.error(res.message);
            // 	// this.tableData=res.data;
            // }
        },
        handleInvoiceAuthCheck(res, file) {
            this.fullscreenLoading.close();
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }
                return false;
            }
            this.uploadFile3 = file;
            this.invoiceUrl = file.url;
            this.invoiceCheckMessage = res.data.message;
            this.invoiceDataList = res.data.data;
        },
        handleRepeatCheck(res, file) {
            this.uploadFile2 = file;
            this.fullscreenLoading.close();
            this.repeatImgUrl = [];
            this.echoImgUrl = file.url;
            this.repeatCaseNo = res.data.caseNo;
            this.repeatImgUrl = res.data.imageUrl ? res.data.imageUrl : [];
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }
                return false;
            }
            if (this.uploadFile2 && res.data.imageUrl) {
                this.uploadFile2_1 = file;
            }
        },
        handlePsDetectionCheck(res, file) {
            this.uploadFile4 = file;
            this.fullscreenLoading.close();
            this.uploadPsData = res.data;
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }
                return false;
            }
        },
        qrCodeHandle(res, file) {
            this.uploadFile5 = file;
            this.fullscreenLoading.close();
            this.qrCodeDataList = res.data.codes_result;
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }

                return false;
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.url = file.url;
            this.unloaderHeader.Authorization = 'Bearer ' + this.$$getToken();
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        searchResult() {
            this.cleanMaotaiResult();
            this.loading = true;
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.isDisabled = true;
            this.instance
                .get(this.baseURL + '/tpa/ocrAbility/maotai?organicCode=' + this.navForm.organicCode)
                .then(({ data }) => {
                    if (data.code === 200) {
                        this.maotaiResult = data.data;
                    } else {
                        this.$message.error('请求接口失败!请稍后重试');
                    }
                    this.isDisabled = false;
                    loading.close();
                })
                .catch((_) => this.$message.error('查询失败!请稍后重试'));
            setTimeout(() => {
                loading.close();
                this.isDisabled = false;
            }, 30000);
        },

        cleanMaotaiResult() {
            this.maotaiResult = {
                certCode: '',
                certAudType: '',
                productCatalogName: '',
                productTypingName: '',
                productDimensionUnit: '',
                markUsePattern: '',
                certAuthorityName: '',
                certOrgName: '',
                certStatus: ''
            };
        }
    }
};
