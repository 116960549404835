
import OSS from 'ali-oss';
export default {
    name: 'ImgDeal',
    props: {
        url: {
            type: String,
            default: null
        },
        points: {
            type: String
        },
        wineCheckFlag: {
            type: Boolean,
            default: false
        },
        imgData: {
            type: Object,
            default: null
        }
    },
    components: {},
    data() {
        return {
            OSSClient: null,
            previewImgData: null,
            retryCount: 0,
            // 图片参数
            params: {
                zoomVal: 1,
                left: 0,
                top: 0,
                currentX: 0,
                currentY: 0
            },
            deg: 0,
            resUrl: '',
            pointsArray: []
        };
    },
    computed: {},
    watch: {
        points: {
            handler(data) {
                if (data) {
                    this.pointsArray = JSON.parse(data);
                } else {
                    this.pointsArray = [];
                }
            },
            immediate: true
        },
        imgData: {
            handler(val) {
                this.previewImgData = null;
                val && this.initOSSClient(val);
            },
            immediate: true
        }
    },
    created() {
        this.restImg();
    },
    updated() {
        this.restImg();
    },
    methods: {
        // 初始化OSS
        initOSSClient(watchImgData) {
            this.instance.get('/tpa/api/aliyun/oss/access').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.OSSClient = new OSS({
                        accessKeyId: data.data.accessKeyId,
                        accessKeySecret: data.data.accessKeySecret,
                        stsToken: data.data.securityToken,
                        // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                        region: data.data.region,
                        bucket: data.data.bucket
                    });
                    // 初始化图片
                    this.initImgUrl(watchImgData);
                } else {
                    console.log('oss初始化失败，正在重试！');
                    if (this.retryCount <= 200) {
                        setTimeout(() => {
                            this.retryCount++;
                            this.initOSSClient();
                        }, 200);
                    } else {
                        console.log('oss长时间初始化失败！');
                    }
                }
            });
        },
        initImgUrl(previewImgData) {
            // materialPath 路径
            // materialOriginalPath 原始路径
            let imgUrl;
            if (previewImgData.materialPath) {
                imgUrl = this.OSSClient.signatureUrl(previewImgData.materialPath);
            } else {
                imgUrl = previewImgData.materialOriginalPath;
            }

            // 校验图片是否正常
            previewImgData && this.checkImgStatus(imgUrl);

            // 判断图片类型
            let imgType = ['.bmp', '.dib', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];
            let tmpUrlArr = imgUrl.split('?');
            tmpUrlArr = tmpUrlArr[0];

            let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
            let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
            if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                let tmpSuffix = suffix.split('%')[0];
                suffix = tmpSuffix ? (tmpSuffix == '.com' ? '.png' : false) : '未识别';
            }
            let imgTypeIndex = imgType.indexOf(suffix);

            // 图片后缀
            this.previewImgData = {
                url: imgUrl,
                suffix: suffix,
                isImg: imgTypeIndex != -1
            };
        },
        checkImgStatus(imgUrl) {
            this.instance.get(imgUrl, { params: {} }).then((res) => {
                if (!res) {
                    this.initOSSClient(this.imgData);
                }
            });
        },
        removePoint() {
            this.pointsArray = [];
        },
        // 初始化数据
        restImg() {
            this.params = {
                zoomVal: 1,
                left: 0,
                top: 0,
                currentX: 0,
                currentY: 0
            };
            this.deg = 0;
            if (this.$refs.previewImg) {
                let img = this.$refs.previewImg;
                img.style.transform = `translate(-50%, -50%) scale(${this.params.zoomVal}) rotate(${this.deg}deg)`;
                img.style.left = '50%';
                img.style.top = '50%';
            }
        },
        // 图片滚动放大
        rollImg(event) {
            if (this.pointsArray.length == 0) {
                this.params.zoomVal += event.wheelDelta / 1200;
                this.rollFunc();
            }
        },
        outImg(flag) {
            if (flag == 'out') {
                this.params.zoomVal -= 0.2;
            } else {
                this.params.zoomVal += 0.2;
            }
            this.rollFunc();
        },
        rollFunc() {
            let e = this.$refs.previewImg;
            if (this.params.zoomVal >= 0.2) {
                e.style.transform = `translate(-50%, -50%) scale(${this.params.zoomVal}) rotate(${this.deg}deg)`;
            } else {
                this.params.zoomVal = 0.2;
                e.style.transform = `translate(-50%, -50%) scale(${this.params.zoomVal}) rotate(${this.deg}deg)`;
                return false;
            }
        },
        // 图片旋转
        rotate(type) {
            let res = this.$refs.previewImg;
            this.deg = type == 'right' ? this.deg + 90 : this.deg - 90;
            res.style.transform = `translate(-50%, -50%) scale(${this.params.zoomVal}) rotate(${this.deg}deg)`;
        },
        // 图片移动
        moveImg(e) {
            e.preventDefault();
            // 获取元素
            let imgWrap = this.$refs.imgCont;
            let img = this.$refs.previewImg;
            let x = e.pageX - img.offsetLeft;
            let y = e.pageY - img.offsetTop;
            // 添加鼠标移动事件
            imgWrap.addEventListener('mousemove', move);
            function move(e) {
                img.style.left = e.pageX - x + 'px';
                img.style.top = e.pageY - y + 'px';
            }
            // 添加鼠标抬起事件，鼠标抬起，将事件移除
            img.addEventListener('mouseup', () => {
                imgWrap.removeEventListener('mousemove', move);
            });
            // 鼠标离开父级元素，把事件移除
            imgWrap.addEventListener('mouseout', () => {
                imgWrap.removeEventListener('mousemove', move);
            });
        },
        clickPointIcon(pointItem, pointIndex) {
            let _this = this;
            this.clickNum = 0;
            // 标识点位，进行截取
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const sourceImage = this.$refs.sourceImage;

            var tmpBlob1;
            var xhr = new XMLHttpRequest();
            xhr.open('get', sourceImage.src, true);
            xhr.responseType = 'blob';
            xhr.onload = function (response) {
                if (this.status == 200) {
                    //这里面可以直接通过URL的api将其转换，然后赋值给img.src
                    //也可以使用下面的preView方法将其转换成base64之后再赋值
                    tmpBlob1 = this.response;

                    let reader = new FileReader();
                    reader.onload = function (event) {
                        let dataUrl = event.target.result;
                        let base64 = dataUrl.split(',')[1];
                        sourceImage.src = 'data:image/png;base64,' + base64;

                        const x = pointItem.interceptX; // 开始截取的 x 坐标
                        const y = pointItem.interceptY; // 开始截取的 y 坐标
                        let width = pointItem.interceptWidth; // 截取的宽度
                        let height = pointItem.interceptHeight; // 截取的高度
                        // 将源图片绘制到 canvas 上，但只绘制指定的区域
                        ctx.drawImage(sourceImage, x, y, width, height, 0, 0, width, height);
                        const dataURL = canvas.toDataURL('image/png');
                        _this.resUrl = dataURL;

                        _this.$refs.resImage.src = dataURL;

                        let formData = new FormData();
                        let blob = _this.dataURLtoBlob(dataURL);
                        let file = new File([blob], 'formData.jpg', { type: 'image/jpeg' }); // 你可以自定义文件名和类型
                        formData.append('file', file);
                        // 截取区域进行识别
                        _this.barcodeAndQrCode(formData);
                    };

                    // 使用readAsDataURL方法读取Blob对象的内容
                    reader.readAsDataURL(tmpBlob1);
                }
            };
            xhr.send();
        },
        barcodeAndQrCode(formData) {
            this.instance.post('/tpa/api/wine/decode/barcodeAndQrCode', formData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.checkedForm.machineCode = data.data;
                    // this.searchResult(true);
                    this.$emit('searchResult', true);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        dataURLtoBlob(dataUrl) {
            let arr = dataUrl.split(','),
                mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }
};
