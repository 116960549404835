
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    props: {
        previewRiskData: {
            typeof: Array,
            default: () => {
                return [];
            }
        }
    },
    components: {
        ImgDeal
    },
    data() {
        return {
            previewImgLoading: true,
            dialogVisible: true,
            imgUrlList: [],
            imgActiveIndex: 0, // 当前移动图片的索引值
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            imageUrl: '',
            previewShow: false,
            imgDialogVisible: false,
            riskActiveIndex: 0,
            riskCardActiveIndex: 0,
            currentActiveLength: 1,
            currentActiveItem: {},
            riskStr: { 0: 'highRiskInfos', 1: 'mediumRiskInfos', 2: 'lowRiskInfos' }
        };
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeRiskDialog', false);
        },
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        },
        changeImg(item, index) {
            this.imgActiveIndex = index;
            this.imageUrl = item.url;
            this.previewShow = true;
        },
        previewImg() {},
        imgLeft() {
            let tmpRiskVos = this.previewRiskData[0];
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--; // 索引值-1
                // 切换大图预览
                this.changeImg(this.imgUrlList[this.imgActiveIndex], this.imgActiveIndex);
            } else {
                this.riskActiveIndex--;
                if (this.riskActiveIndex < 0) {
                    this.switchLeft();
                } else {
                    let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                    this.switchRiskImg(currentRiskData, currentRiskData[this.riskActiveIndex], this.riskActiveIndex, this.riskCardActiveIndex);
                }
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) {
                        // 移动次数(33次)
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.allDistance = 0;
                this.imgActiveIndex++;
                // 切换大图预览
                this.changeImg(this.imgUrlList[this.imgActiveIndex], this.imgActiveIndex);
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
                // 方框索引
                // riskCardActiveIndex
                // 当前选中项索引
                // riskActiveIndex
                // 方框中风险数量
                // currentActiveLength
                // 切回首项-高风险
                if (this.riskActiveIndex == this.currentActiveLength - 1) {
                    let highRiskInfos = this.previewRiskData[0].highRiskInfos;
                    this.riskCardActiveIndex = 0;
                    this.riskActiveIndex = 0;
                    // 列表,0项,当前风险索引0,高风险的索引=0
                    this.switchRiskImg(highRiskInfos, highRiskInfos[0], 0, 0);
                } else {
                    // 当前风险方框中,切换下个风险
                    this.riskActiveIndex++;
                    let tmpRiskVos = this.previewRiskData[0];
                    let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                    this.switchRiskImg(currentRiskData, currentRiskData[this.riskActiveIndex], this.riskActiveIndex, this.riskCardActiveIndex);
                }
            }
        },
        switchLeft() {
            // 方框索引
            // riskCardActiveIndex
            // 当前选中项索引
            // riskActiveIndex
            // 方框中风险数量
            // currentActiveLength
            // 继续向前切换
            let tmpRiskVos = this.previewRiskData[0];
            if (this.riskActiveIndex <= 0) {
                this.riskCardActiveIndex--;
                if (this.riskCardActiveIndex < 0) {
                    // 切换至最后一个方框
                    this.riskCardActiveIndex = 2;
                    this.riskActiveIndex = 0;
                    let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                    if (currentRiskData) {
                        this.switchRiskImg(currentRiskData, currentRiskData[currentRiskData.length - 1], currentRiskData.length - 1, this.riskCardActiveIndex);
                    } else {
                        this.switchLeft();
                    }
                } else {
                    // 向上一个方框切换
                    let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                    if (currentRiskData) {
                        // 列表,0项,当前风险索引0,方框的索引
                        this.switchRiskImg(currentRiskData, currentRiskData[currentRiskData.length - 1], currentRiskData.length - 1, this.riskCardActiveIndex);
                    } else {
                        // 当前风险数=0时，页面不展示风险，自动向上切换
                        this.currentActiveLength = 1;
                        this.riskActiveIndex = 0;
                        this.switchLeft();
                    }
                }
            } else {
                // 向上一个风险切换
                this.riskActiveIndex--;
                let tmpRiskVos = this.previewRiskData[0];
                let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                if (currentRiskData) {
                    this.switchRiskImg(currentRiskData, currentRiskData[this.riskActiveIndex], this.riskActiveIndex, this.riskCardActiveIndex);
                }
            }
        },
        switchRight() {
            // 方框索引
            // riskCardActiveIndex
            // 当前选中项索引
            // riskActiveIndex
            // 方框中风险数量
            // currentActiveLength
            // 继续向后切换
            let tmpRiskVos = this.previewRiskData[0];
            if (this.riskActiveIndex == this.currentActiveLength - 1) {
                this.riskCardActiveIndex++;
                if (this.riskCardActiveIndex > 2) {
                    // 切换至第一个方框
                    this.riskCardActiveIndex = 0;
                    this.riskActiveIndex = 0;
                } else {
                    // 切换下个风险方框切换
                    let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                    if (currentRiskData) {
                        // 列表,0项,当前风险索引0,方框的索引
                        this.switchRiskImg(currentRiskData, currentRiskData[0], 0, this.riskCardActiveIndex);
                    } else {
                        // 当前风险数=0时，页面不展示风险，自动向下切换
                        this.currentActiveLength = 1;
                        this.riskActiveIndex = 0;
                        this.switchRight();
                    }
                }
            } else {
                // 当前风险方框中,切换下个风险
                this.riskActiveIndex++;
                let tmpRiskVos = this.previewRiskData[0];
                let currentRiskData = tmpRiskVos[this.riskStr[this.riskCardActiveIndex]];
                this.switchRiskImg(currentRiskData, currentRiskData[this.riskActiveIndex], this.riskActiveIndex, this.riskCardActiveIndex);
            }
        },
        switchRiskImg(riskItemList, riskItem, riskIndex, parentIndex) {
            this.previewImgLoading = false;

            // parentIndex 0-1-2  高-中-低
            this.imgUrlList = [];
            this.currentActiveLength = riskItemList.length;
            this.riskCardActiveIndex = parentIndex;
            this.riskActiveIndex = riskIndex;
            this.currentActiveItem = riskItem;
            if (riskItem.imageList) {
                this.imgUrlList = riskItem.imageList;
                this.changeImg(riskItem.imageList[0], 0);
            }
        }
    }
};
