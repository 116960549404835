
export default {
    name: 'Waybill',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            txtHoverStyleObj: {
                flag0: false,
                flag1: false,
                flag1: false
            }
        };
    },
    methods: {
        setTxtOverFlowStyle() {
            let ellipsisEls = document.querySelectorAll('.ellipsisWaybill');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        elementShow: {
            handler(val) {
                if (val) {
                    this.setTxtOverFlowStyle();
                }
            },
            immediate: true
        }
    }
};
