
export default {
    name: 'CloseCase',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        }
    },
    data() {
        return {};
    }
};
