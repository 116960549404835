
// @ is an alias to /src
import * as Options from './options';
import ExpressionBuilder from '@/components/ExpressionBuilder';
export default {
    name: 'StrategyAdmin',
    components: {
        ExpressionBuilder
    },
    data() {
        return {
            loading: false,
            innerVisible: false,
            activeName: '风控',
            ruleDataFrame: {
                data: [],
                total: 0
            },
            riskPointForm: {
                riskName: '', // 风险名称
                riskType: '', // 风险类型  文本、缺少图片、异常图片
                riskStep: '', // 风险步骤  报案风险、立案风险、理算风险
                riskLevel: '', // 风险等级  低、中、高
                riskCategory: '', // 风险类别  A 欺诈 B 免责 C 赔付减免  D 案件提示
                riskDesc: '' // 风险描述
            },
            actionData: [
                {
                    action: ''
                }
            ],
            action2Data: [
                {
                    action1: '',
                    action2: '等于',
                    value: '',
                    unit: ''
                }
            ],
            publicAction: {
                action: ''
            },
            publicAction2: {
                action1: '',
                action2: '',
                value: '',
                unit: ''
            },
            notActionData: [
                {
                    action: ''
                }
            ],
            featureDesc: {},
            featureOption: [],
            featureOption2: [],
            ruleDetail: {},
            ruleDetail2: {
                name: '满足所有规则'
            },
            features: [],
            ...Options,
            ruleEditor: {
                visible: false,
                title: '新增策略'
            },
            query: {
                type: '',
                typeName: '',
                symbol: '',
                children: [
                    {
                        field: '',
                        fieldName: '',
                        operator: '',
                        operatorDesc: '',
                        symbol: '',
                        term: '',
                        termName: '',
                        constant: '',
                        children: {
                            operator: '',
                            operatorDesc: '',
                            symbol: '',
                            term: '',
                            termName: '',
                            constant: ''
                        }
                    }
                ]
            }
        };
    },
    methods: {
        getRuleList() {
            this.instance.post('/risk/api/rule/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
                const list = data.data.list;
                const columns = [];
                const values = list.map((item) => item.featureName);
                for (var i = 0; i < list.length; i++) {
                    const feature = list[i];
                    columns.push({
                        field: feature.ruleCode,
                        label: feature.ruleName,
                        type: Options.getDataType(feature.dataType)
                    });
                }
                this.features = columns;
            });
        },
        //添加动作
        addAction() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction));
            this.actionData.push(publicAction);
        },
        //删除动作
        deleteAction(items, index) {
            if (this.actionData.length > 1) {
                this.actionData.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        //添加动作
        addAction2() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction2));
            this.action2Data.push(publicAction);
        },
        //删除动作
        deleteAction2(items, index) {
            if (this.action2Data.length > 1) {
                this.action2Data.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        //点击添加命中新风险点
        addRiskPoint(items, index) {
            this.innerVisible = true;
        },
        //点击提交命中新风险点
        submitRiskPoint() {
            let riskPointForm = JSON.stringify(this.riskPointForm);
            let json = {
                scene: '风控',
                featureName: this.riskPointForm.riskName,
                featureDesc: riskPointForm
            };
            this.instance.post('/risk/api/feature/definition/add', json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message);
                    this.innerVisible = false;
                    this.getFeature();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //添加未命中动作
        addNotAction() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction));
            this.notActionData.push(publicAction);
        },
        action1Change(items) {
            items.unit = items.action1 == 'FEATURE1627607185521831936' ? '%' : '元';
        },
        actionChange(val) {
            let opt = {};
            opt = this.featureOption.find((item) => {
                return item.featureCode === val;
            });
            if (opt) {
                this.featureDesc = JSON.parse(opt.featureDesc);
            } else {
                this.featureDesc = {};
            }
        },
        //删除未命中动作
        deleteNotAction(items, index) {
            if (this.notActionData.length > 1) {
                this.notActionData.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        onStatusChange(row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/strategy/definition/edit', { code: row.strategyCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.status == 0 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '策略' + row.strategyName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('策略' + row.strategyName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        editRules(ruleCode) {
            this.$data.ruleEditor.visible = true;
            this.$data.ruleDetail = {};
            this.query = this.$options.data().query;
            if (ruleCode) {
                this.$data.loading = true;
                this.$data.ruleEditor.title = '编辑策略';
                this.instance
                    .post('/risk/api/strategy/definition/' + ruleCode)
                    .then(({ data }) => {
                        this.$data.loading = false;
                        this.ruleDetail = data.data;
                    })
                    .catch((error) => {
                        this.$data.loading = false;
                        this.$message.error('加载策略详情失败，网络错误！');
                    });
            } else {
                this.$data.ruleEditor.title = '新增策略';
                this.ruleDetail.scene = this.activeName;
            }
        },
        ruleUpload() {
            this.loading = true;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let ruleDetail = JSON.parse(JSON.stringify(this.ruleDetail));
                    ruleDetail.scene = this.activeName;
                    let rule = {
                        ...ruleDetail,
                        output: {
                            failFeatureValue: '',
                            featureCode: '',
                            featureValue: ''
                        }
                    };
                    if (this.activeName == '风控') {
                        rule.output.featureCode = this.actionData[0].action;
                        let opt = {};
                        opt = this.featureOption.find((item) => {
                            return item.featureCode === this.actionData[0].action;
                        });
                        rule.output.featureValue = opt.featureName;
                    } else {
                        rule.output.featureCode = this.action2Data[0].action1;
                        rule.output.featureValue = this.action2Data[0].value + this.action2Data[0].unit;
                    }
                    rule.formula = this.queryToSql(this.query);
                    rule.formulaShow = this.queryToSqlShow(this.query);
                    // console.log(this.ruleDetail.code)
                    let sign = this.ruleDetail.code ? 'edit' : 'config';
                    this.instance
                        .post('/risk/api/strategy/definition/' + sign, rule)
                        .then(({ data }) => {
                            this.$data.loading = false;
                            if (data.code == 200) {
                                this.$message({ message: '策略配置成功', type: 'success' });
                                this.ruleEditor.visible = false;
                                this.fetchRuleDataFrame(1);
                            } else {
                                this.$message({ message: data.message, type: 'error' });
                            }
                        })
                        .catch((err) => {
                            this.$data.loading = false;
                            this.$message({ message: '保存失败，网络错误', type: 'error' });
                        });
                } else {
                    this.$message({ message: '策略配置错误，无法提交，请重新填写！', type: 'error' });
                }
            });
        },

        handleClose: function () {
            this.ruleEditor.visible = false;
        },
        fetchRuleDataFrame(currentPage) {
            this.loading = true;
            let json = {
                condition: {
                    scene: this.activeName
                },
                pageNo: currentPage,
                pageSize: 10
            };
            this.instance.post('/risk/api/strategy/definition/paging', json).then((res) => {
                this.loading = false;
                this.ruleDataFrame = {
                    data: res.data.data.list,
                    total: parseInt(res.data.data.total),
                    current: parseInt(res.data.data.current)
                };
            });
        },
        //获取特征定义
        getFeature() {
            let json = {
                condition: {
                    scene: '风控'
                },
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/risk/api/feature/definition/paging', json).then((res) => {
                if (res.data.code == 200) {
                    this.featureOption = res.data.data.list;
                }
            });
        },
        getFeature2() {
            let json = {
                condition: {
                    scene: '核赔'
                },
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/risk/api/feature/definition/paging', json).then((res) => {
                if (res.data.code == 200) {
                    this.featureOption2 = res.data.data.list;
                }
            });
        }
    },
    mounted() {
        //
        this.baseURL = this.$store.getters.baseURL;
        this.fetchRuleDataFrame(1);
        this.getRuleList();
        this.getFeature();
        this.getFeature2();
    }
};
