
// import OSS from 'ali-oss';
import CaseRisk from './CaseRisk.vue';
import ImgDeal from '@/components/ImgDeal.vue';

export default {
    name: 'Claimant',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        isEditInfo: {
            type: Boolean,
            default: false
        },
        tmpOSSClient: {
            type: Object,
            default: null
        },
        showPopup: {
            type: Boolean,
            default: false
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    components: {
        CaseRisk,
        ImgDeal
    },
    data() {
        return {
            isJdDuty: false,
            jdDialogFlag: false,
            processingType: 1,
            isFold: true,
            activeName: 'first',
            caseMaterialsType: null, //材料类型
            fileTypeList: [],
            queryFileLoading: false,
            checkedInvoice: {
                voList: []
            },
            OSSClient: null,
            imgUrlList: [],
            imgActiveIndex: 0, // 当前移动图片的索引值
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            imageUrl: '',
            previewShow: false,
            imgDialogVisible: false,
            liActiveIndex: 0,
            previewUrl: '',
            showCaseList: [
                {
                    enumList: [],
                    files: [],
                    parentCode: 0,
                    parentName: ''
                }
            ],
            thumbnailActiveIndex: 0,
            subsetIndex: 0,
            titleIndex: 1,
            tmpPreviewIndex: 0,
            showCaseListRealityLength: 0,
            initialIndex: 0,
            repeatTable: [],
            wineOptions: [],
            checkedForm: {
                wineType: '',
                materialId: '',
                code: ''
            },
            checkedFormRules: {
                wineType: [{ required: true, message: '请选择酒类', trigger: ['blur', 'change'] }],
                code: [
                    { required: true, message: '请输入溯源编号', trigger: ['blur', 'change'] },
                    { min: 13, max: 22, message: '长度在 13 到 22 个字符', trigger: ['blur', 'change'] }
                ]
            },
            testValue: 1,
            resUrl: '',
            hotPointArr: [],
            identifyLoading: false,
            clickNum: 0,
            resWineData: {},
            wineCheckFlag: false,
            imgWineData: null,
            previewImgURL: '',
            previewImgIndex: 0,
            pathStr: null,
            queryWineFlag: false,
            caseNoListFlag: false,
            txtHoverStyleObj: {
                flag0: false,
                flag1: false,
                flag2: false,
                flag3: false,
                flag4: false,
                flag5: false,
                flag6: false
            },
            updateHoverStyle: false,
            typeOptions: [],
            value: '',
            manualMarkEnum: [],
            manualMarkObj: {},
            manualMarkData: [],
            currentManualOptionsObj: {},
            manualData: {
                caseMaterialsTypeEnum: '',
                lossDegree: '',
                damageType: '',
                fragile: '',
                remark: ''
            },
            manualDataRules: {
                caseMaterialsTypeEnum: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                lossDegree: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                damageType: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                fragile: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                amountConsistent: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                forgery: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                purchaseRecord: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                purchaseDate: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                samePositionLoss: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                waybillConsistent: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            },
            imgTypeChangeVal: null,
            manualDataEnumObj: {},
            switchFlag: false,
            showTxtFlag: false,
            previewImgTypeObk: {},
            currentPreviewImgTypeIndex: null,
            isOutGoodInLess: false,
            voucherTypeOptions: [
                {
                    label: '发票',
                    code: 0
                },
                {
                    label: '出库单',
                    code: 1
                },
                {
                    label: '手写价值证明',
                    code: 2
                },
                {
                    label: '平台售价',
                    code: 3
                },
                {
                    label: '购买记录',
                    code: 4
                },
                {
                    label: '商家订单记录',
                    code: 5
                },
                {
                    label: '维修单',
                    code: 6
                },
                {
                    label: '机打单据',
                    code: 7
                },
                {
                    label: '微信截图',
                    code: 8
                },
                {
                    label: '转账截图',
                    code: 9
                },
                {
                    label: '鉴定证书',
                    code: 10
                },
                {
                    label: '报价单',
                    code: 11
                },
                {
                    label: '其他',
                    code: 12
                }
            ],
            similarObj: {},
            currentImgId: '',
            similarShowFlag: true
        };
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        },
        computedStyle() {
            if (this.similarObj && this.similarObj[this.currentImgId]) {
                return {
                    width: `${this.similarObj[this.currentImgId].length * 90}px`
                };
            } else {
                return {
                    width: '80px'
                };
            }
        }
    },
    mounted() {
        this.pathStr = this.$route.path;

        // 酒类品种
        this.getWineType();
        this.getManualMark();
    },
    methods: {
        getMaterialsSimilar(caseNo) {
            this.similarData = {};
            this.instance.post('/tpa/api/case/materials/similar', { caseNo: caseNo }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.similarData = data.data;
                    for (const key in this.similarData) {
                        this.similarObj[key] = this.similarData[key];
                        if (this.similarObj[key] && this.similarObj[key].length > 0) {
                            this.similarObj[key].forEach((item) => {
                                item.previewUrl = item.materialOriginalPath ? item.materialOriginalPath : this.OSSClient.signatureUrl(item.materialPath);
                            });
                        }
                    }
                    console.log('相似图查询：');
                    console.log(this.currentImgId);
                    console.log(this.similarObj[this.currentImgId]);
                }
            });
        },
        showAccProcess() {
            this.showTxtFlag = !this.showTxtFlag;
        },
        getManualMark() {
            this.instance.get('/tpa/api/enum/getManualMarkEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.manualMarkEnum = data.data;
                    this.manualMarkEnum.forEach((item) => {
                        let tmpDesc = item.caseMaterialsTypeEnum.desc == '发票' ? '价值证明' : item.caseMaterialsTypeEnum.desc;
                        this.manualMarkObj[item.caseMaterialsTypeEnum.code] = item;
                        this.manualDataEnumObj[item.caseMaterialsTypeEnum.desc] = {
                            desc: tmpDesc,
                            code: item.caseMaterialsTypeEnum.code,
                            combination: tmpDesc + '一' + item.caseMaterialsTypeEnum.code
                        };
                    });

                    this.splitOptions();
                }
            });
        },
        splitOptions() {
            this.typeOptions = [];

            this.manualMarkEnum.forEach((item) => {
                let tmpDesc = item.caseMaterialsTypeEnum.desc == '发票' ? '价值证明' : item.caseMaterialsTypeEnum.desc;
                this.typeOptions.push({
                    code: item.caseMaterialsTypeEnum.code,
                    desc: tmpDesc
                });
            });
        },
        identifyClick() {
            let _this = this;
            let tmpBlob;

            if (!this.checkedForm.wineType) {
                this.$message.error('请先选择酒类品种');
                return;
            }
            this.identifyLoading = true;
            const previewImg = document.querySelector('#previewImg');

            let imgObj = new Image();
            imgObj.src = this.previewImgURL;

            var xhr = new XMLHttpRequest();
            xhr.open('get', this.previewImgURL, true);
            xhr.responseType = 'blob';
            xhr.onload = function (response) {
                if (this.status == 200) {
                    tmpBlob = this.response;
                    let decodeFormData = new FormData();
                    let file = new File([tmpBlob], 'decodeImg.jpg', { type: 'image/jpeg' }); // 你可以自定义文件名和类型
                    decodeFormData.append('file', file);
                    // 调用识别接口，等待接口返回识别坐标点
                    _this.instance.post('/tpa/api/wine/detect/barcodeAndQrCode', decodeFormData).then((res) => {
                        let data = res.data;

                        _this.identifyLoading = false;
                        if (data.code == 200) {
                            _this.setHotPointArr(data.data);
                        } else {
                            _this.$message.error(data.message);
                        }
                    });
                }
            };
            xhr.send();
        },
        setHotPointArr(pointData) {
            // pointData = [
            //     [
            //         [1019, 505],
            //         [1018, 374],
            //         [1323, 372],
            //         [1324, 503]
            //     ],
            //     [
            //         [170, 168],
            //         [170, 153],
            //         [392, 162],
            //         [391, 177]
            //     ]
            // ];
            let _this = this;
            let originalPointData = JSON.parse(JSON.stringify(pointData));
            const tmpParentEl = document.querySelector('#imgDeal' + this.previewImgIndex);
            const previewImg = tmpParentEl.querySelector('.bigImage');
            this.$refs.sourceImage.src = previewImg.src;
            if (previewImg.src && this.$refs.sourceImage.src) {
                // 计算当前展示图片与源图尺寸比例
                let img = new Image();
                img.src = previewImg.src;
                // 当图片加载完成时
                img.onload = function () {
                    // 获取图片展示尺寸
                    const tmpParentEl1 = document.querySelector('#imgDeal' + _this.previewImgIndex);
                    const imgBox = tmpParentEl1.querySelector('.bigImage');

                    console.log('宽度: ' + imgBox.offsetWidth);
                    console.log('高度: ' + imgBox.offsetHeight);
                    // 获取图片的原始宽高
                    var originalWidth = imgBox.naturalWidth;
                    var originalHeight = imgBox.naturalHeight;

                    var container = document.querySelector('.imgCont');

                    // 获取图片容器的宽高
                    var containerWidth = container.offsetWidth;
                    var containerHeight = container.offsetHeight;

                    // 计算等比缩放后的宽高
                    var scale = Math.min(containerWidth / originalWidth, containerHeight / originalHeight);
                    var scaledWidth = originalWidth * scale;
                    var scaledHeight = originalHeight * scale;

                    // 输出等比缩放后的宽高
                    console.log('等比缩放后的宽度: ' + scaledWidth);
                    console.log('等比缩放后的高度: ' + scaledHeight);

                    // 计算图片容器和图片原图展示比例
                    let boxWidthRatio = (scaledWidth / img.naturalWidth).toFixed(3);
                    let boxHeightRatio = (scaledHeight / img.naturalHeight).toFixed(3);

                    // 计算容器与等比缩放后的高度差
                    var heightDifference = (containerHeight - scaledHeight) / 2;

                    // 展示点位信息
                    // start
                    // test1
                    //   [ 170  128] 左上
                    //   [ 170  193] 左下
                    //   [ 392  202] 右下
                    //   [ 391  137] 右上
                    let tmpHotObj = {};
                    let tmpHotPointArr = [];
                    pointData.forEach((res, index) => {
                        // scale
                        // let tmpRes

                        res.forEach((rowItem, rowIndex) => {
                            rowItem.forEach((tmpItem, tmpIndex) => {
                                rowItem[tmpIndex] = (scale * tmpItem).toFixed(2);
                            });
                            res[rowIndex] = rowItem;
                        });
                        pointData[index] = res;
                        let roundDot = res[0][1] < res[1][1] ? res[0] : res[1];

                        // res[0] val
                        // 截取图片区域的宽度 392-170 = 222
                        let interceptWidth = res[2][0] - res[0][0];
                        // 截取图片区域的高度 // 193-128 = 65
                        let interceptHeight = Math.abs(res[1][1] - res[0][1]);
                        // 标识点x坐标
                        let pointWidth = res[2][0] - interceptWidth / 2;
                        let pointX = Math.round(pointWidth.toFixed(2));
                        // 标识点Y坐标
                        //  + Number(interceptHeight);
                        let pointHeight = Number(res[1][1]);
                        let pointY = Math.round(pointHeight + heightDifference).toFixed(2);
                        console.log('pointY');
                        console.log(pointY);
                        tmpHotObj = {
                            interceptX: roundDot[0],
                            interceptY: roundDot[1],
                            interceptWidth: interceptWidth,
                            interceptHeight: interceptHeight,
                            pointX: pointX, // 标识x
                            pointY: pointY // 标识y
                        };

                        tmpHotPointArr.push(tmpHotObj);
                    });
                    tmpHotPointArr.push({ time: new Date() });
                    // end
                    // 点位选取数据
                    _this.hotPointArr = tmpHotPointArr;
                };
            }
        },

        getWineType() {
            this.instance.get('/tpa/api/enum/getWineTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.wineOptions = data.data;
                }
            });
        },
        searchResult(codeFlag) {
            this.$refs.checkedFormRef.validate((valid) => {
                if (valid || this.hotPointArr.length > 0) {
                    this.identifyLoading = true;
                    this.checkedForm.materialId = this.imgUrlList[this.titleIndex - 1].id;
                    let tmpForm = JSON.parse(JSON.stringify(this.checkedForm));

                    if (String(codeFlag) == 'true') {
                        tmpForm.code = tmpForm.machineCode;
                    }

                    this.instance.post('/tpa/api/wine/authentication', tmpForm).then((res) => {
                        this.identifyLoading = false;
                        this.queryWineFlag = true;
                        let data = res.data;
                        if (data.code == 200) {
                            this.resWineData = data.data;
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                }
            });
        },
        checkedWine() {
            // 处理当前图片是否携带酒类验真信息
            this.switchClearWineData(0);
            if (this.imgUrlList.length > 0) {
                this.wineCheckFlag = true;
                this.previewImg(this.imgUrlList[0].url, 0);
            }
            // setTimeout(() => {
            //     this.setHotPointArr('data');
            // }, 1000);
        },
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1);
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1);
                }
            } else {
                this.$refs.carouselRef.prev();
                this.initialIndex--;
                this.switchClearWineData();
            }
            this.queryManualData();
            this.switchFlag = true;
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(null, 0, this.showCaseList[0], 0);
                } else {
                    this.subsetChange(null, 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1);
                }
            } else {
                this.$refs.carouselRef.next();
                this.initialIndex++;
                this.switchClearWineData();
            }
            this.switchFlag = true;
            this.queryManualData();
        },
        subsetChange(items, itemsIndex, item, index) {
            this.switchClearWineData();
            // end
            this.subsetIndex = itemsIndex;
            this.thumbnailActiveIndex = index;
            // 当前图片id
            this.currentImgId = item.files && item.files[itemsIndex] ? item.files[itemsIndex].id : '';

            this.switchCarousel(item, index);
            this.initialIndex = itemsIndex;
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(itemsIndex);

            this.queryManualData();
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1;
            this.subsetIndex = rowIndex;
            // 当前图片index
            this.tmpPreviewIndex = rowIndex;
            // 处理当前图片是否携带酒类验真信息
            this.switchClearWineData(rowIndex);
        },
        closeImgDialog() {
            this.imgDialogVisible = false;
            this.wineCheckFlag = false;
            this.identifyLoading = false;
            this.checkedForm = {
                wineType: '',
                materialId: '',
                code: ''
            };
            this.queryWineFlag && this.$emit('getCaseDetail');
            this.queryWineFlag = false;
        },
        previewImg(src, i) {
            this.previewUrl = src;
            this.imgActiveIndex = i;
            this.imgDialogVisible = true;
            this.similarShowFlag = true;
            this.subsetIndex = i;
            this.tmpPreviewIndex = i;
            this.initialIndex = i;
            this.queryManualData();
            // 右侧案件列表标题
            this.caseNoListTitle();
            // 当前图片id
            this.currentImgId = this.imgUrlList[this.initialIndex].id;

            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(i);
        },
        changeImg(item, index) {
            this.imgActiveIndex = index;
            this.imageUrl = item;
            this.previewShow = true;
        },
        imgLeft() {
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--; // 索引值-1
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) {
                        // 移动次数(33次)
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.allDistance = 0;
                this.imgActiveIndex++;
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
                // 到达最后一张图片，再点击跳转回第一张
                this.imgActiveIndex = 0;
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < Math.abs(this.allDistance / 2)) {
                        // 取绝对值再除
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 1);
            }
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.resetOss();
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        resetOss() {
            if (this.caseDetail.materialsList && this.caseDetail.materialsList.length > 0) {
                if (this.OSSClient) {
                    this.checkImgUrl(this.OSSClient.signatureUrl(this.caseDetail.materialsList[0].materialPath));
                } else {
                    this.$emit('initOSSClient');
                }
            }
        },
        checkImgUrl(url) {
            this.instance.get(url).then((res) => {
                if (!res) {
                    this.$emit('initOSSClient');
                }
            });
        },
        //初始化案件索赔材料
        initMaterialsList(ossClient) {
            if (this.caseMaterialsType && this.caseMaterialsType.length > 0) {
                if (this.caseDetail.materialsList && this.caseDetail.materialsList.length > 0) {
                    for (let index = 0; index < this.caseMaterialsType.length; index++) {
                        const element = this.caseMaterialsType[index];
                        element.files = [];
                        this.caseDetail.materialsList.forEach((val, key) => {
                            // 9903 查勘费用其他材料
                            if (val.materialType != 9903 && val.materialParentType == element.parentCode) {
                                if (ossClient) {
                                    val.url = val.materialPath ? ossClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                                } else {
                                    val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                                }

                                // 图片后缀
                                val.suffix = this.$$getFileSuffix(val.url);
                                let suffix = this.$$getFileSuffix(val.url);
                                if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                                    let tmpSuffix = suffix.split('%')[0];
                                    suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                                }
                                val.suffix = suffix;
                                val.isImg = Boolean(suffix != -1);

                                element.files.push(val);

                                this.$set(this.caseMaterialsType, index, element);
                            }
                        });
                    }

                    // 获取存在图片的类型
                    let realityCaseTypeList = [];
                    this.showCaseListRealityLength = 0;
                    this.caseMaterialsType &&
                        this.caseMaterialsType.forEach((item, index) => {
                            // parentCode 100 查勘报告
                            if (item.parentCode != 100) {
                                if (item.files && item.files.length > 0) {
                                    this.showCaseListRealityLength++;
                                    realityCaseTypeList.push(item);
                                }
                            }
                        });

                    this.showCaseList = realityCaseTypeList;

                    if (realityCaseTypeList.length > 0) {
                        this.switchCarousel(this.showCaseList[0], 0);
                        this.liActiveIndex = 0;
                    }
                }
            } else {
                setTimeout(() => {
                    ossClient && this.initMaterialsList(ossClient);
                }, 1000);
            }
        },
        materialDialog(rowData) {
            if (rowData.files && rowData.files.length > 0) {
                this.dialogVisible = true;
            }
        },
        switchCarousel(data, index) {
            this.imgDistance = 0;
            this.imgActiveIndex = 0;
            this.liActiveIndex = index;
            this.titleIndex = 1;
            this.thumbnailActiveIndex = index;
            if (data.files && data.files.length > 0) {
                this.imgUrlList = data.files;
            }
        },
        switchClearWineData(index) {
            this.resWineData = {};
            // 切换时清空酒类验真数据
            // this.checkedForm.wineType = '';
            this.hotPointArr = [];
            // 处理当前图片是否携带酒类验真信息

            if (index >= 0 && this.imgUrlList[index]) {
                this.imgWineData = this.imgUrlList[index].wineAuthenticationResult;
                this.resWineData = this.imgWineData ? this.imgWineData : null;
                this.previewImgURL = this.imgUrlList[index].url;
                // 当前展示图片index
                this.previewImgIndex = index;
                // 当前图片id
                this.currentImgId = this.imgUrlList[index].id;
            }
        },
        caseNoListTitle() {
            if (this.imgUrlList[this.initialIndex] && this.imgUrlList[this.initialIndex].similarCaseNoList && this.imgUrlList[this.initialIndex].repeatCaseNos) {
                this.caseNoListFlag = true;
            } else {
                this.caseNoListFlag = false;
            }
        },
        setTxtOverFlowStyle(updateFlag) {
            let ellipsisEls = document.querySelectorAll('.ellipsis1');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    if (updateFlag) {
                        this.updateHoverStyle = true;
                    }
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        },
        handlerClick(typeNum) {
            this.processingType = typeNum;
            this.jdDialogFlag = true;
        },
        submitJdDialog(submitFlag) {
            if (submitFlag) {
                if (this.$route.path == '/caseInnerQuality/manualReview') {
                    this.saveIsJdDuty();
                } else {
                    this.notJdDuty();
                }
            } else {
                this.jdDialogFlag = false;
            }
        },
        queryIsJdDuty(caseNo) {
            this.instance.post('/tpa/api/case/judge/isJdDuty/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.isJdDuty = !data.data;
                    if (this.$route.path != '/caseInnerQuality/manualReview') {
                        this.isJdDuty = !this.caseDetail.damageInfo.jdDuty;
                    }
                }
            });
        },
        saveIsJdDuty() {
            // let apiUrl = this.processingType == 1 ? '/tpa/api/case/adjust/review/isJdDuty/' : '/tpa/api/case/judge/isOutGoodInLess/';
            let apiUrl = this.processingType == 1 ? '/tpa/api/case/adjust/review/isJdDuty/' : '/tpa/api/case/adjust/review/isOutGoodInLess/';
            this.instance.post(apiUrl + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.submitJdDialog(false);
                    this.getManualReviewList();
                }
            });
        },
        notJdDuty() {
            let apiUrl = this.processingType == 1 ? '/tpa/api/case/step/notJdDuty/' : '/tpa/api/case/step/isOutGoodInLess/';
            this.instance.post(apiUrl + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$emit('getCaseDetail');
                    this.submitJdDialog(false);
                    this.$message.success('操作成功！');
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getManualReviewList() {
            let queryData = {
                condition: {
                    caseInTimeStart: '',
                    caseInTimeEnd: '',
                    caseNo: '',
                    reviewStatus: [20],
                    reviewUserIds: []
                },
                pageNo: 1,
                pageSize: 10,
                total: 0
            };
            let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
            queryData.condition.reviewUserIds = [userInfo.userId];
            this.instance.post('/tpa/api/case/review/record/paging', queryData).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    if (data.data.list && data.data.list.length > 0) {
                        // this.handleCaseList(data.data.list[0].caseNo);
                        this.$emit('getCaseDetail', data.data.list[0].caseNo);
                    } else {
                        this.$parent.$parent.$emit('getReviewListData');
                        setTimeout(() => {
                            this.$message.error('当前暂无可作业案件！');
                        }, 1000);
                    }
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        typeChange(val) {
            let tmpVal = JSON.parse(JSON.stringify(val));
            tmpVal = Number(val.split('一')[1]);

            // 选择器选项
            this.imgTypeChangeVal = tmpVal;
            for (const key in this.manualData) {
                if (key != 'caseMaterialsTypeEnum' && key != 'damageType') {
                    this.manualData[key] = '';
                } else if (key == 'damageType') {
                    this.manualData[key] = [];
                }
            }

            // this.$refs.ruleForm.validate((valid) => {});

            // this.$nextTick(() => {
            //     this.$refs.ruleForm.validate((valid) => {});
            // });
        },
        setSelectOptions(typeStr) {
            let options = [];
            if (this.manualData.caseMaterialsTypeEnum) {
                let tmpVal = Number(this.manualData.caseMaterialsTypeEnum.split('一')[1]);

                let tmpData = this.manualMarkObj[tmpVal] ? this.manualMarkObj[tmpVal].manualAnnotationMaterialProblem : {};

                for (const key in tmpData) {
                    if (key == typeStr) {
                        options = tmpData[key];
                    }
                }
            }
            return options;
        },
        // 查询人工标注
        queryManualData() {
            let tmpData = {
                caseMaterialsTypeEnum: '',
                lossDegree: '',
                damageType: '',
                fragile: '',
                remark: ''
            };
            let currentPreviewImgData = this.imgUrlList[this.initialIndex];
            let currentPreviewImgType = this.showCaseList[this.thumbnailActiveIndex].parentName;
            // this.imgTypeChangeVal = null;
            let consistent = true;
            if (this.currentPreviewImgTypeIndex != this.thumbnailActiveIndex) {
                this.currentPreviewImgTypeIndex = JSON.parse(JSON.stringify(this.thumbnailActiveIndex));
                consistent = false;
            } else {
                consistent = true;
            }

            if (currentPreviewImgData && currentPreviewImgData.signHash) {
                this.instance.post('/tpa/api/manual/annotation/get/' + currentPreviewImgData.signHash, {}).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        // 人工标注回显
                        if (data.data) {
                            let resManualData = data.data.annotationResult.manualAnnotationMaterialProblem;
                            let structureManualData = this.manualDataEnumObj[resManualData.caseMaterialsTypeEnum];
                            let structureManualType = structureManualData ? structureManualData.combination : '';
                            resManualData.caseMaterialsTypeEnum = structureManualType;
                            currentPreviewImgData.manualData = resManualData;
                            this.imgTypeChangeVal = structureManualData ? structureManualData.code : '';
                            this.manualData = currentPreviewImgData.manualData || tmpData;
                        } else if (!consistent) {
                            this.imgTypeChangeVal = null;
                            currentPreviewImgData.manualData = tmpData;
                            this.manualData = currentPreviewImgData.manualData;
                        }
                        this.switchFlag = false;
                    }
                });
            }
        },
        // 添加人工标注
        saveManualData() {
            let tmpManualData = JSON.parse(JSON.stringify(this.manualData));
            let typeEnumSplitArr = tmpManualData.caseMaterialsTypeEnum.split('一');
            let tmpKey = typeEnumSplitArr[0];
            let tmpVal = Number(typeEnumSplitArr[1]);

            let currentPreviewImgData = this.imgUrlList[this.initialIndex];
            if (currentPreviewImgData.signHash) {
                // this.imgTypeChangeVal = null;
                // manualAnnotationMaterialProblem
                let addData = {
                    annotationResult: null,
                    hash: currentPreviewImgData.signHash
                };
                let tmpObj = JSON.parse(JSON.stringify(this.manualMarkObj));
                let sourceManualData = tmpObj[tmpVal];
                tmpManualData.caseMaterialsTypeEnum = tmpKey;
                sourceManualData.manualAnnotationMaterialProblem = tmpManualData;
                addData.annotationResult = sourceManualData;

                this.instance.post('/tpa/api/manual/annotation/add', addData).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.$message.success('保存成功!');
                    } else {
                        this.$message.error(data.message);
                    }
                });
            }
        },
        getIsOutGoodInLess(caseNo) {
            this.instance.post('/tpa/api/case/judge/isOutGoodInLess/' + caseNo, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.isOutGoodInLess = data.data;
                }
            });
        }
    },
    updated() {
        !this.updateHoverStyle && this.setTxtOverFlowStyle(1);
    },
    watch: {
        caseDetail: {
            handler(data) {
                if (data.caseNo) {
                    this.getFileTypeList();
                    this.queryIsJdDuty(data.caseNo);
                    // 模型预测是否外好内少
                    this.getIsOutGoodInLess(data.caseNo);
                    // 初始化案件材料
                    this.OSSClient && this.initMaterialsList(this.OSSClient);
                    // 获取案件相似图片缩略图
                    if (this.$route.path == '/caseInnerQuality/innerQualityManage') {
                        this.getMaterialsSimilar(data.caseNo);
                    }
                }
            },
            immediate: true
        },
        tmpOSSClient: {
            handler(val) {
                if (val) {
                    console.log('claimant');
                    console.log(val);

                    this.OSSClient = val;
                    this.initMaterialsList(val);
                }
            },
            immediate: true
        },
        elementShow: {
            handler(val) {
                val && this.setTxtOverFlowStyle(0);
            },
            immediate: true
        }
    }
};
