
export default {
    name: 'CaseLog',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        }
    },
    data() {
        return {
            hidden: true,
            impairmentData: {
                caseLossAmount: null,
                caseLossFormula: null
            }
        };
    },
    methods: {
        handleFoldHidden(hide) {
            this.$emit('hiddenCaseLog');
        },
        getCaseLoss(caseNo) {
            this.instance.get('/tpa/api/case/enter/adjust/getCaseLoss', { params: { caseNo: caseNo } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.impairmentData = data.data;
                }
            });
        }
    },
    watch: {
        caseDetail: {
            handler(val) {
                // 获取减损计算公式
                val.caseNo && this.getCaseLoss(val.caseNo);

                // 80注销 页面展示结案
                val.caseNo &&
                    val.stepLogList.forEach((item) => {
                        if (item.caseNextStep == 80) {
                            item.caseNextStepName = '结案';
                        }
                    });
            },
            immediate: true
        }
    }
};
