
import CaseList from '@/components/CaseList.vue';

export default {
    components: {
        CaseList
    },
    data() {
        return {
            activeName: '1',
            userInfo: {},
            personalCaseTotal: {},
            personalCaseOfficial: {},
            personalCaseOfficialRanking: {},
            personalPreCaseOfficial: {},
            personalPreCaseOfficialRanking: {},

            currentPage: 1,
            pageSize: 10,
            totalPage: 1,
            waitLossMenuType: '40',
            preLossMenuType: '15',
            waitClaimMenuType: '49',
            navFormShow: false,
            isShowWaitClaimTab: false
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getPersonalCaseTotal();
        this.getPersonalCaseOfficial();
        this.getPersonalCasePre();
        this.checkIsShowWaitClaimTab();
    },
    methods: {
        getPersonalCaseTotal() {
            this.instance.get('/tpa/api/homepage/personal/case/total').then((res) => {
                if (res.data.code === 200) {
                    this.personalCaseTotal = res.data.data;
                }
            });
        },

        getPersonalCaseOfficial() {
            this.instance.get('/tpa/api/homepage/personal/case/official').then((res) => {
                if (res.data.code === 200) {
                    let personalCaseOfficial = {};
                    let personalCaseOfficialRanking = {};
                    res.data.data.forEach((item) => {
                        if (item.metricsName === '案均结案时效') {
                            personalCaseOfficial.officialCloseTime = item.metricsValue;
                            personalCaseOfficialRanking.officialCloseTimeRanking = item.rank;
                        }
                        if (item.metricsName === '结案率') {
                            personalCaseOfficial.officialCloseRate = item.metricsValue;
                            personalCaseOfficialRanking.officialCloseRateRanking = item.rank;
                        }
                        if (item.metricsName === '减损总金额') {
                            personalCaseOfficial.lossAmount = item.metricsValue;
                            personalCaseOfficialRanking.lossAmountRanking = item.rank;
                        }
                        if (item.metricsName === '减损率') {
                            personalCaseOfficial.lossRate = item.metricsValue;
                            personalCaseOfficialRanking.lossRateRanking = item.rank;
                        }
                        if (item.metricsName === '案均减损金额') {
                            personalCaseOfficial.avgLossAmount = item.metricsValue;
                            personalCaseOfficialRanking.avgLossAmountRanking = item.rank;
                        }
                    });
                    this.personalCaseOfficial = personalCaseOfficial;
                    this.personalCaseOfficialRanking = personalCaseOfficialRanking;
                }
            });
        },

        getPersonalCasePre() {
            this.instance.get('/tpa/api/homepage/personal/case/pre').then((res) => {
                if (res.data.code === 200) {
                    let personalPreCaseOfficial = {};
                    let personalPreCaseOfficialRanking = {};
                    res.data.data.forEach((item) => {
                        if (item.metricsName === '案均完成时效') {
                            personalPreCaseOfficial.officialCloseTime = item.metricsValue;
                            personalPreCaseOfficialRanking.officialCloseTimeRanking = item.rank;
                        }
                        if (item.metricsName === '完成率') {
                            personalPreCaseOfficial.officialCloseRate = item.metricsValue;
                            personalPreCaseOfficialRanking.officialCloseRateRanking = item.rank;
                        }
                        if (item.metricsName === '接案量') {
                            personalPreCaseOfficial.lossAmount = item.metricsValue;
                            personalPreCaseOfficialRanking.lossAmountRanking = item.rank;
                        }
                        if (item.metricsName === '预定损案件量') {
                            personalPreCaseOfficial.lossRate = item.metricsValue;
                            personalPreCaseOfficialRanking.lossRateRanking = item.rank;
                        }
                        if (item.metricsName === '已完成案件量') {
                            personalPreCaseOfficial.avgLossAmount = item.metricsValue;
                            personalPreCaseOfficialRanking.avgLossAmountRanking = item.rank;
                        }
                    });
                    this.personalPreCaseOfficial = personalPreCaseOfficial;
                    this.personalPreCaseOfficialRanking = personalPreCaseOfficialRanking;
                }
            });
        },

        jumpToWorkbench() {
            let _path = '/workbench/myTask';
            this.$router.push({ path: _path });
        },

        checkIsShowWaitClaimTab() {
            let tmpTabsArr = [];
            let stagingArr = localStorage.getItem('stagingArr') ? JSON.parse(localStorage.getItem('stagingArr')) : [];
            stagingArr.length > 0 &&
                stagingArr.forEach((item) => {
                    tmpTabsArr.push({
                        title: item.name,
                        menuTypeNum: item.type
                    });
                });
            // 按照 menuTypeNum 查找是否存在
            tmpTabsArr.forEach((item) => {
                if (item.menuTypeNum === '50') {
                    this.isShowWaitClaimTab = true;
                }
            });
        }
    }
};
