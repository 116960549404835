
// @ is an alias to /src
export default {
    name: 'riskPointManage',
    components: {},
    data() {
        return {
            loading: false,
            dialogVisible: false,
            navForm: {
                orderNo: '',
                goodsType: '',
                auditor: '',
                accStartTime: '',
                accEndTime: '',
                reportStartTime: '',
                reportEndTime: '',
                inflowStartTime: '',
                inflowEndTime: ''
            },
            riskForm: {
                riskName: '',
                riskCategory: '',
                riskStep: '',
                riskLevel: '',
                riskType: '',
                riskDesc: ''
            },
            caseEndStepOption: [],
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: []
        };
    },
    methods: {
        //点击添加按钮
        handleRisk() {
            this.$refs.riskForm && this.$refs.riskForm.resetFields();
            this.dialogVisible = true;
        },
        //点击提交
        handleAddRiskPiont() {},
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.pageNo = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/risk/api/risk/definition/paging', json).then((res) => {
                // console.log(res);
                this.loading = false;
                this.tableData = res.data.data.list;
                this.totalPage = parseInt(res.data.data.total);
            });
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm.resetFields();
        },
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/rule/definition/edit', { code: row.ruleCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.status == 0 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '规则' + row.ruleName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('规则' + row.ruleName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            // console.log(val);
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        this.getTbodyData();
    }
};
