
import OSS from 'ali-oss';
import CaseList from '@/components/CaseList.vue';
import LossPage from '@/components/caseInfo/lossPage.vue';
import AdjustmentPage from '@/components/caseInfo/adjustmentPage.vue';
import ForecastCasePage from '@/components/caseInfo/ForecastCasePage.vue';
import RegisterPage from '@/components/caseInfo/registerPage.vue';

export default {
    name: 'casePage',
    components: {
        CaseList,
        LossPage,
        AdjustmentPage,
        ForecastCasePage,
        RegisterPage
    },
    data() {
        return {
            tmpActiveName: '',
            menuType: 'searchPage',
            menuTypeNum: 30,
            editableTabsValue: '30',
            editableTabs: [
                {
                    title: '正式案件',
                    menuTypeNum: '1'
                },
                {
                    title: '预报案案件',
                    menuTypeNum: '2'
                }
            ],
            tabDictionary: {
                案件查询: '1',
                预报案案件: '2'
            },
            tabIndex: 2,
            handleFlag: false,
            handleData: {},
            OSSClient: null,
            tableHeight: 0
        };
    },
    mounted() {
        this.renderTable();
    },
    methods: {
        renderTable() {
            let numStr = '';
            let tmpTabsArr = [];
            let stagingArr = localStorage.getItem('stagingArr') ? JSON.parse(localStorage.getItem('stagingArr')) : [];
            if (this.menuType != 'searchPage') {
                stagingArr.length > 0 &&
                    stagingArr.forEach((item) => {
                        tmpTabsArr.push({
                            title: item,
                            menuTypeNum: this.tabDictionary[item]
                        });
                    });
                this.editableTabs = tmpTabsArr;
            }
            switch (this.$route.query.menuType) {
                case '10':
                    numStr = '40';
                    break;
                case '49':
                    numStr = '50';
                    break;
                case '15':
                    numStr = '15';
                    break;

                default:
                    numStr = '1';
                    break;
            }
            this.editableTabsValue = numStr;
            this.menuTypeNum = Number(numStr);

            if (this.$route.query.caseNo && this.$route.query.query == 'query') {
                let key = this.$route.query.caseNo.split('E') ? this.$route.query.caseNo.split('E')[0] : null;
                this.editableTabsValue = key == 'CAS' ? '2' : '1';
                if (key == 'CAS') {
                    setTimeout(() => {
                        this.clickTab({ name: this.editableTabsValue });
                    }, 500);
                }
            }
        },
        addTab() {
            this.editableTabs.push({
                title: '案件详情',
                menuTypeNum: '99'
            });
        },
        removeTab(targetName) {
            let tabs = this.editableTabs;
            this.handleFlag = false;
            this.editableTabsValue = this.tmpActiveName;
            this.clickTab({ name: this.menuTypeNum });
            if (targetName == '99') {
                this.editableTabs = tabs.filter((tab) => tab.menuTypeNum !== targetName);
            }
        },
        clickTab(tabRow) {
            if (tabRow.name == 99) {
                this.handleFlag = true;
            } else {
                let tmpIndex = this.editableTabs.findIndex((item) => {
                    return item.menuTypeNum == '99';
                });
                this.handleFlag = tabRow.name == 99 ? true : false;
                this.menuTypeNum = Number(tabRow.name);
                if (tmpIndex == -1) {
                    this.handleData.menuTypeNum = Number(tabRow.name);
                }
            }
        },
        handleCase(data) {
            this.handleData = JSON.parse(JSON.stringify(data));
            this.handleData.menuType = this.menuType;

            if (this.handleData && this.handleData.closeCaseDetail && this.handleData.tmpHandleData) {
                this.handleData = this.handleData.tmpHandleData;
            }

            // 添加详情tab
            let tmpIndex = this.editableTabs.findIndex((item) => item.menuTypeNum == '99');
            const tmpName = this.editableTabsValue;
            this.tmpActiveName = tmpName;
            tmpIndex == -1 && this.addTab();
            this.editableTabsValue = '99';
            this.handleFlag = true;
        },
        closeCaseDetail(switchData) {
            switchData ? this.handleCase(switchData) : this.removeTab('99');
        }
    }
};
