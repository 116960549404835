
import OSS from 'ali-oss';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ReportCase from './ReportCase.vue';
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    name: 'CaseInfo',
    components: {
        ReportCase,
        ImgDeal
    },
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        isBtnShow: {
            typeof: Boolean,
            default: false
        },
        tmpOSSClient: {
            type: Object,
            default: null
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            isFold1: true,
            surveyCostRequiredForm: {
                suggestedClaimAmount: '',
                surveyTime: '',
                damageReason: ''
            },
            editSurveyOtherInfo: false,
            surveyCostDialog: false,
            dialogForm: {
                surveyCostForm: [
                    {
                        sheng: '',
                        surveyFeeType: '',
                        surveyFee: '',
                        accountName: '',
                        accountNo: '',
                        accountProvinceName: '',
                        accountProvinceCode: '',
                        accountCityName: '',
                        accountCityCode: '',
                        accountBranchBankName: '',
                        accountBranchBankCode: '',
                        accountBankCode: '',
                        accountBank: '',
                        payObject: '',
                        accountType: '',
                        isExceptionPay: '',
                        exceptionPayReason: '',
                        exceptionReasonDesc: '',
                        bank1disabled: false,
                        bank2disabled: true,
                        bank3disabled: true
                    }
                ],
                report: [],
                report1: []
            },
            surveyCostRules: {},
            options: [],
            value: '',
            input: '',
            feeTypeOptions: [],
            payObjTypeOptions: [],
            surveyorTypeOptions: [],
            bankListOptions: [],
            branchListOptions: [],
            exceptionPayOption: [
                {
                    code: 1,
                    name: '是'
                },
                {
                    code: 0,
                    name: '否'
                }
            ],
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            bank1Options: [],
            bank2Options: [],
            bank3Options: [],
            bank1Obj: {},
            bank2Obj: {},
            bank3Obj: {},
            surveyInfo: {},
            surveyCostData: [{}],
            surveyCostInfo: [{}],
            surveyDialog: false,
            surveyForm: {
                planSurveyTime: '',
                surveyPersonType: '',
                surveyPersonName: '',
                surveyPersonPhone: '',
                surveyPersonIdCard: ''
            },
            surveyRules: {
                planSurveyTime: [{ required: true, message: '请选择计划查勘时间', trigger: ['blur', 'change'] }],
                surveyPersonType: [{ required: true, message: '请选择查勘人员身份类型', trigger: ['blur', 'change'] }],
                surveyPersonName: [{ required: true, message: '请填写联系人姓名', trigger: ['blur', 'change'] }],
                surveyPersonPhone: [
                    { required: true, message: '请填写联系人手机号', trigger: ['blur', 'change'] },
                    {
                        validator: function (rule, value, callback) {
                            if (/^((0\d{2,3}-\d{7,8})|(1[345789]\d{9}))$/.test(value) == false) {
                                callback(new Error('联系电话格式错误'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }
                ],
                surveyPersonIdCard: [
                    { required: true, message: '请填写联系人身份证', trigger: ['blur', 'change'] },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/.test(value) == false) {
                                callback(new Error('身份证格式错误'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }
                ]
            },
            surveyTypeOptions: [],
            liActiveIndex: 0,
            imgUrlList: [],
            imgActiveIndex: 0, // 当前移动图片的索引值
            imageUrl: '',
            OSSClient: null,
            caseMaterialsType: null, //材料类型
            previewShow: false,
            previewUrl: '',
            imgDialogVisible: false,
            subsetIndex: 0,
            tmpPreviewIndex: 0,
            initialIndex: 0,
            surveyMaterialList: [],
            pdfPreviewFlag: false,
            pdfPreviewDialogFlag: false,
            imgDialogVisible: false,
            showCaseList: [],
            thumbnailActiveIndex: 0,
            subsetIndex: 0,
            titleIndex: 1,
            tmpPreviewIndex: 0,
            showCaseListRealityLength: 0,
            initialIndex: 0,
            previewHeight: 0,
            isEdit: true,
            txtHoverStyleObj: {
                flag0: false,
                flag1: false,
                flag2: false,
                flag3: false,
                flag4: false,
                flag5: false
            },
            surveyUpdateStyle: false
        };
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    mounted() {
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.baseURL = this.$store.getters.baseURL;
        // 费用类型
        this.getFeeTypeEnum();
        // 支付对象
        this.getPayObjectTypeEnum();
        // 收款人类型
        this.getSurveyorTypeEnum();
        // 总行列表
        this.getBankList();
    },
    methods: {
        subsetChange(items, itemsIndex, item, index) {
            // 预览弹窗 pdf 状态
            if (item.parentCode == 100) {
                this.pdfPreviewDialogFlag = true;
                this.previewUrl = item.files[0].url;
            } else {
                this.pdfPreviewDialogFlag = false;
                this.previewUrl = null;
            }
            this.subsetIndex = itemsIndex;
            this.thumbnailActiveIndex = index;
            this.switchCarousel(item, index);
            this.initialIndex = itemsIndex;
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(itemsIndex);
        },
        changeDialogFlag(variableStr, flag) {
            this[variableStr] = flag;
        },
        submitSurvey() {
            this.$refs.surveyRef.validate((valid) => {
                if (valid) {
                    this.surveyForm.caseNo = this.caseDetail.caseNo;
                    this.instance
                        .post('/tpa/api/case/survey/insuranceSurveyor', this.surveyForm)
                        .then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                // 获取查勘信息
                                this.getSurveyInfo();
                                // 更新案件日志
                                this.$emit('surveyBtn', 'log');
                                this.$message.success('查勘信息保存成功！');
                                this.surveyDialog = false;
                            } else {
                                this.$message.error(data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$message.error('请求失败, 请稍后重试');
                        });
                }
            });
        },
        submitFeeBack(submitFlag) {
            let _this = this;
            this.surveyCostRequiredForm.caseNo = this.caseDetail.caseNo;
            this.$refs.surveyCostRequiredRef.validate((valid) => {
                if (valid && submitFlag) {
                    this.instance.post('/tpa/api/case/survey/feedback', this.surveyCostRequiredForm).then((res) => {
                        let data = res.data;
                        if (data.code == 200) {
                            this.getSurveyInfo();
                            // 更新案件日志
                            _this.$emit('surveyBtn', 'log');
                            this.$message.success('查勘结束！');
                        }
                    });
                } else if (valid) {
                    // 弹窗确认后提交查勘实际日期信息
                    _this.$emit('surveyBtn', 'ok?');
                }
            });
        },
        getSurveyInfo() {
            this.instance.post(`/tpa/api/case/survey/info/get/${this.caseDetail.caseNo}`).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.surveyInfo = data.data;
                    // 设置查勘费用数据及定损卡片是否可编辑
                    setTimeout(() => {
                        this.$emit('caseCardParentVariable', { variableStr: 'surveyCostInfo', flag: data.data });
                    }, 500);
                    // 获取查勘信息后，分析展示查勘系列操作按钮
                    this.editSurveyStatus(this.surveyInfo);

                    // 实际勘查时间等 - 编辑状态
                    if (this.surveyInfo.surveyPersonName && !this.surveyInfo.surveyTime) {
                        this.editSurveyOtherInfo = false;
                    } else {
                        this.editSurveyOtherInfo = true;
                        this.getSurveyCost();
                    }
                }
            });
        },
        // 查勘信息状态
        editSurveyStatus(surveyInfo) {
            // 查勘信息状态分析
            // 查勘案件显示查勘信息tab
            // 显示处理按钮 可以编辑查勘信息
            if (this.isBtnShow && surveyInfo) {
                // 实际勘查时间不存在，显示查勘按钮组
                // 否则，不展示查勘操作按钮
                if (!surveyInfo.surveyTime) {
                    this.surveyEdit = true;
                    this.$emit('caseCardParentVariable', { variableStr: 'surveyEdit', flag: true });
                } else {
                    this.$emit('caseCardParentVariable', { variableStr: 'surveyEdit', flag: false });
                }

                // 身份证信息不存在，显示反馈查勘信息按钮
                // 否则，显示查勘结束按钮
                if (!surveyInfo.surveyPersonId) {
                    // 查勘人员身份类型
                    this.getSurveyorType();
                    this.$emit('caseCardParentVariable', { variableStr: 'overBtnShow', flag: false });
                } else {
                    this.$emit('caseCardParentVariable', { variableStr: 'overBtnShow', flag: true });
                }
            }
        },
        getSurveyCost() {
            this.instance.post(`/tpa/api/case/survey/fee/get/${this.caseDetail.caseNo}`).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.surveyCostInfo = data.data;
                }
            });
        },
        addSurveyCostList() {
            let tmpObj = {
                sheng: '',
                surveyFeeType: '',
                surveyFee: '',
                accountName: '', //	账户名称
                accountNo: '', // 账户号码
                accountProvinceName: '', // 开户行省名称
                accountProvinceCode: '', // 开户行省代码
                accountCityName: '', // 开户行市名称
                accountCityCode: '', // 开户行市代码
                accountBranchBankName: '', // 开户行支行名称
                accountBranchBankCode: '', // 开户行支行代码
                accountBankCode: '', // 开户行代码
                accountBank: '', // 开户行名称
                payObject: '',
                accountType: '',
                isExceptionPay: '',
                exceptionPayReason: '',
                exceptionReasonDesc: '',
                bank1disabled: false,
                bank2disabled: true,
                bank3disabled: true
            };
            this.dialogForm.surveyCostForm.push(tmpObj);
            this.getBankList(null, tmpObj, this.dialogForm.surveyCostForm.length - 1);
        },
        delCostForm(item, index) {
            this.dialogForm.surveyCostForm.splice(index, 1);
        },
        submitDialogSurveyCost() {
            let _this = this;
            this.$refs.surveyCostRef.validate((valid) => {
                if (valid) {
                    // 查勘费用
                    this.dialogForm.surveyCostForm.forEach((item) => {
                        item.caseNo = this.caseDetail.caseNo;
                    });
                    let tmpFileList = this.dialogForm.report.concat(this.dialogForm.report1);
                    Promise.all([this.saveFee(this.dialogForm.surveyCostForm), this.saveFile(tmpFileList)])
                        .then(
                            axios.spread((...res) => {
                                _this.surveyCostDialog = false;
                                _this.$message.success('查勘费用及查勘材料保存成功！');
                            })
                        )
                        .catch((errors) => {
                            // 如果任一请求失败，会走到这里
                            console.error('Error occurred:', errors);
                            _this.$message.error('请求失败，请稍后重试！');
                        });
                }
            });
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleBigImg = true;
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {},
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            // this.fullscreenLoading = this.$loading({
            //     lock: true,
            //     text: '上传中，请稍后',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // })
        },
        //OSS直传
        ossUpload(e, fileType) {
            const OSS = require('ali-oss');
            let _this = this;
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.')[1];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl = null;

            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            if (fileType && fileType != tmpSuffix) {
                this.$message.error('请选择正确文件类型');
                return;
            }

            if (!fileType && fileTypeList.indexOf(tmpSuffix) == -1) {
                this.$message.error('请选择正确文件类型');
                return;
            }
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                setTimeout(() => {
                    client
                        .multipartUpload(storeAs, file)
                        .then(function (result) {
                            // 材料父类型
                            // 公估报告，100
                            // 其他材料，99

                            // 材料类型
                            // 公估报告9902
                            // 其他材料9901
                            // storeAs - 阿里返回图片路径
                            if (fileType) {
                                _this.dialogForm.report.push({
                                    caseNo: _this.caseDetail.caseNo,
                                    materialName: file.name,
                                    materialPath: storeAs,
                                    materialParentType: 100,
                                    materialType: 9902,
                                    requestUrl: tmpImageUrl
                                });
                                _this.$refs.surveyCostRef.validate((valid) => {});
                            } else {
                                _this.dialogForm.report1.push({
                                    caseNo: _this.caseDetail.caseNo,
                                    materialName: file.name,
                                    materialPath: storeAs,
                                    materialParentType: 99,
                                    materialType: 9903,
                                    requestUrl: tmpImageUrl
                                });
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }, 500);
            });
        },
        // 开户省市
        getFeeTypeEnum1() {
            this.instance.get('/tpa/api/enum/getFeeTypeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.option = data.data;
                }
            });
        },
        // 费用类型
        getFeeTypeEnum() {
            this.instance.get('/tpa/api/enum/getFeeTypeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.feeTypeOptions = data.data;
                }
            });
        },
        // 支付对象
        getPayObjectTypeEnum() {
            this.instance.get('/tpa/api/enum/getPayObjectTypeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.payObjTypeOptions = data.data;
                }
            });
        },
        // 收款人类型
        getSurveyorTypeEnum() {
            this.instance.get('/tpa/api/enum/getAccountTypeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.surveyorTypeOptions = data.data;
                }
            });
        },
        // 总行列表
        getBankList(queryVal, item, index) {
            let tmpItem, tmpIndex;
            this.instance.post('/tpa/api/bankInfo/bankList', { bankName: queryVal || null }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (item) {
                        tmpIndex = index;
                        tmpItem = JSON.parse(JSON.stringify(item));
                    } else {
                        tmpIndex = 0;
                        tmpItem = JSON.parse(JSON.stringify(this.dialogForm.surveyCostForm[0]));
                    }
                    tmpItem.bankListOptions = data.data;
                    // 更新form表单
                    this.$set(this.dialogForm.surveyCostForm, tmpIndex, tmpItem);

                    this.bank2Obj = {};
                    this.bankListOptions.forEach((item) => {
                        this.bank2Obj[item.bankCode] = item;
                    });
                }
            });
        },
        // 支行省市列表
        getBranchList(val, item, index) {
            let tmpItem = JSON.parse(JSON.stringify(item));
            let tmpIndex = index;
            this.instance.get('/tpa/api/bankInfo/getBranchListByBankCode?bankCode=' + val, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    tmpItem.bank2Obj = {};
                    data.data.forEach((item) => {
                        // 处理数据，第三级方便使用
                        tmpItem.bank2Obj[item.provinceCode] = item;
                        item.cityName = item.provinceName;
                        item.cityCode = item.provinceCode;
                    });
                    tmpItem.branchListOptions = data.data;
                    tmpItem.bank2disabled = false;

                    // 更新form表单
                    this.$set(this.dialogForm.surveyCostForm, tmpIndex, tmpItem);
                }
            });
        },
        remoteMethod(queryVal, item, index) {
            // 设置开户行总行options
            this.getBankList(queryVal, item, index);
        },
        bankChange1(val, item, index) {
            // 清空省市
            item.sheng = '';
            // 清空支行选项
            item.accountBranchBankCode = '';
            item.bank3disabled = true;
            // bank1Obj[val] = {bankCode: "B1001",bankName: "交通银行"}
            // B1001
            // 处理设置开户省市options
            this.getBranchList(val, item, index);
        },
        bankChange2(val, item, index) {
            // 清空支行选项
            item.accountBranchBankCode = '';
            // val => [12,98]
            // bank2Obj => {12:{cityList:[{}]}}
            // 处理支行名称options数据
            let tmpItem = JSON.parse(JSON.stringify(item));
            let tmpIndex = index;
            if (val.length > 1 && tmpItem.bank2Obj[val[0]]) {
                tmpItem.bank3Obj = {};
                tmpItem.bank2Obj[val[0]].cityList.forEach((item) => {
                    tmpItem.bank3Obj[item.cityCode] = item;
                });
                tmpItem.bank3Options = tmpItem.bank3Obj[val[1]].branchList;
                tmpItem.bank3disabled = false;
                // 更新form表单
                this.$set(this.dialogForm.surveyCostForm, tmpIndex, tmpItem);
            }
        },
        bankChange3(val, item, index) {
            let tmpBank3Obj = {};
            // 获取最终要提交数据
            let tmpItem = JSON.parse(JSON.stringify(item));
            tmpItem.bank3Options.forEach((item) => {
                tmpBank3Obj[item.branchBankCode] = item;
            });
            // 开户行
            tmpItem.accountBank = tmpBank3Obj[val].bankName;
            tmpItem.accountBankCode = tmpBank3Obj[val].bankCode;
            // 开户行省
            tmpItem.accountProvinceName = tmpBank3Obj[val].provinceName;
            tmpItem.accountProvinceCode = tmpBank3Obj[val].provinceCode;
            // 开户行市
            tmpItem.accountCityName = tmpBank3Obj[val].cityName;
            tmpItem.accountCityCode = tmpBank3Obj[val].cityCode;
            // 开户行支行
            tmpItem.accountBranchBankName = tmpBank3Obj[val].branchBankName;
            tmpItem.accountBranchBankCode = tmpBank3Obj[val].branchBankCode;

            // 汇总数据
            this.$set(this.dialogForm.surveyCostForm, index, tmpItem);
        },
        saveFee(surveyCostForm) {
            this.instance.post('/tpa/api/case/survey/fee/save', surveyCostForm).then((res) => {
                let data = res.data;
                if (data.code != 200) {
                    this.$message.error(data.message);
                } else {
                    this.getSurveyInfo();
                }
            });
        },
        saveFile(fileList) {
            this.instance.post('/tpa/api/case/survey/materials', fileList).then((res) => {
                let data = res.data;
                if (data.code != 200) {
                    this.$message.error(data.message);
                } else {
                    this.$emit('handleCaseList', this.caseDetail.caseNo);
                    this.$emit('initOSSClient', this.caseDetail.caseNo);
                }
            });
        },
        // 初始化查勘材料
        initCaseMaterials(materialsData) {
            let tmpMaterialData = [];
            materialsData.forEach((res) => {
                if ((res.materialType && res.materialType == 9903) || res.materialType == 9902) {
                    tmpMaterialData.push(res);
                }
            });
            // 查勘材料数据
            this.surveyMaterialList = tmpMaterialData;
        },
        switchCarousel(data, index) {
            this.imgActiveIndex = 0;
            this.liActiveIndex = index;
            this.titleIndex = 1;
            this.thumbnailActiveIndex = index;
            if (data.files && data.files.length > 0) {
                let tmpImageList = [];
                this.imgUrlList = data.files;
                this.$forceUpdate();
            }
            // 100 查勘报告
            this.pdfPreviewFlag = data.parentCode == 100;
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.resetOss();
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        //初始化案件索赔材料
        initMaterialsList(ossClient) {
            if (this.caseMaterialsType && this.caseMaterialsType.length > 0) {
                if (this.surveyMaterialList && this.surveyMaterialList.length > 0) {
                    for (let index = 0; index < this.caseMaterialsType.length; index++) {
                        const element = this.caseMaterialsType[index];
                        element.files = [];
                        this.surveyMaterialList.forEach((val, key) => {
                            if (val.materialParentType == element.parentCode) {
                                if (ossClient) {
                                    val.url = val.materialPath ? ossClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                                } else {
                                    val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                                }
                                // if (key == 0) {
                                //     val.url = 'asdf.docx?OSSAccessKeyId='
                                // }
                                let imgType = ['.bmp', '.dib', '.com', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];
                                // 判断图片类型
                                let tmpUrlArr = val.url.split('?');
                                tmpUrlArr = tmpUrlArr[0];
                                let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                                let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                                if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                                    let tmpSuffix = suffix.split('%')[0];
                                    suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                                }

                                // 图片后缀
                                val.isImg = suffix != -1 ? imgType.indexOf(suffix) : -1;
                                val.suffix = suffix;

                                element.files.push(val);
                                this.$set(this.caseMaterialsType, index, element);
                            }
                        });
                    }

                    // 获取存在图片的类型
                    let realityCaseTypeList = [];
                    this.showCaseListRealityLength = 0;
                    this.caseMaterialsType &&
                        this.caseMaterialsType.forEach((item, index) => {
                            if (item.files && item.files.length > 0) {
                                this.showCaseListRealityLength++;
                                realityCaseTypeList.push(item);
                            }
                        });

                    this.showCaseList = realityCaseTypeList;

                    if (realityCaseTypeList.length > 0) {
                        this.switchCarousel(this.showCaseList[0], 0);
                        this.liActiveIndex = 0;
                    }
                }
            } else {
                setTimeout(() => {
                    this.initMaterialsList(ossClient);
                }, 1000);
            }
        },
        resetOss() {
            if (this.OSSClient) {
                if (this.caseDetail.materialsList && this.caseDetail.materialsList.length > 0) {
                    if (this.OSSClient) {
                        this.checkImgUrl(this.OSSClient.signatureUrl(this.caseDetail.materialsList[0].materialPath));
                    } else {
                        this.$emit('initOSSClient');
                    }
                }
            }
        },
        checkImgUrl(url) {
            this.instance.get(url).then((res) => {
                if (!res) {
                    this.$emit('initOSSClient');
                }
            });
        },
        getSurveyorType() {
            this.instance.get('/tpa/api/enum/getSurveyorTypeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.surveyTypeOptions = data.data;
                }
            });
        },
        removeFile(fileType, item, index) {
            if (fileType == 'pdf') {
                this.dialogForm.report = [];
            } else {
                this.dialogForm.report1.splice(index, 1);
            }
        },
        previewImg(src, i, fileType) {
            // 预览弹窗 pdf 状态
            this.pdfPreviewDialogFlag = fileType == 'pdf';
            this.previewUrl = src;
            this.imgActiveIndex = i;
            this.imgDialogVisible = true;
            this.subsetIndex = i;
            this.tmpPreviewIndex = i;
            this.initialIndex = i;
            this.$refs.carouselRef && this.$refs.carouselRef.setActiveItem(i);
        },
        changeImg(item, index) {
            this.imgActiveIndex = index;
            this.imageUrl = item;
            this.previewShow = true;
        },
        imgLeft() {
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--; // 索引值-1
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) {
                        // 移动次数(33次)
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.allDistance = 0;
                this.imgActiveIndex++;
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
                // 到达最后一张图片，再点击跳转回第一张
                this.imgActiveIndex = 0;
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < Math.abs(this.allDistance / 2)) {
                        // 取绝对值再除
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 1);
            }
        },
        closeImgDialog() {
            this.imgDialogVisible = false;
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0;
                    this.subsetChange(this.showCaseList[this.showCaseList.length - 1], tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1);
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0;
                    this.subsetChange(this.showCaseList[this.thumbnailActiveIndex - 1], tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1);
                }
            } else {
                this.$refs.carouselRef.prev();
                this.initialIndex--;
            }
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(this.showCaseList[0], 0, this.showCaseList[0], 0);
                } else {
                    this.subsetChange(this.showCaseList[this.thumbnailActiveIndex + 1], 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1);
                }
            } else {
                this.$refs.carouselRef.next();
                this.initialIndex++;
            }
        },
        calcHeight() {
            let headerHeight = document.querySelector('.el-dialog__header') ? document.querySelector('.el-dialog__header').clientHeight : 0;
            let guHeight = 185;
            this.previewHeight = window.innerHeight - headerHeight - guHeight + 'px';
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1;
            this.subsetIndex = rowIndex;
            // 当前图片index
            this.tmpPreviewIndex = rowIndex;
        },
        setTxtOverFlowStyle(updateFlag) {
            let ellipsisEls = document.querySelectorAll('.ellipsisSurveyCase');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    if (updateFlag) {
                        this.surveyUpdateStyle = true;
                    }
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    updated() {
        if (!this.surveyUpdateStyle) {
            setTimeout(() => {
                this.setTxtOverFlowStyle(1);
            }, 500);
        }
    },
    watch: {
        caseDetail: {
            handler(val) {
                if (localStorage.getItem('detailShow')) {
                    this.isEdit = localStorage.getItem('detailShow') ? false : true;
                }
                if (val.caseNo) {
                    this.getFileTypeList();
                    this.getSurveyInfo();
                    this.initCaseMaterials(val.materialsList);
                }
            },
            immediate: true
        },
        tmpOSSClient: {
            handler(val) {
                if (val) {
                    console.log('surveyCase');
                    console.log(val);
                    this.OSSClient = val;
                    this.initMaterialsList(val);
                }
            },
            immediate: true
        },
        elementShow: {
            handler(val) {
                if (val) {
                    this.setTxtOverFlowStyle(0);
                }
            },
            immediate: true
        }
    }
};
