
export default {
    name: 'caseList',
    components: {},
    props: {
        menuType: {
            type: String,
            default: '99'
        },
        menuTypeNum: {
            type: Number,
            default: 15
        }
    },
    data() {
        return {
            dataLoading: false,
            goodsVisible: false,
            dialogTitle: '添加药品',
            navForm: {
                condition: {
                    isCalibration: 0,
                    name: ''
                },
                total: 0,
                pageNo: 1,
                pageSize: 10
            },
            tabPaneObj: [
                {
                    label: '待确认',
                    name: 'first',
                    code: 0
                },
                {
                    label: '已入库',
                    name: 'second',
                    code: 1
                }
            ],
            medicineRules: {
                medicineName: [{ required: true, message: '请输入药品名称', trigger: ['blur', 'change'] }],
                medicineProperty: [{ required: true, message: '请输入药品性质', trigger: 'change' }]
            },
            tableHeight: null,
            medicineForm: {},
            medicineList: [],
            medicinePropertyOptions: [],
            medicineTypeOptions: []
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.getMedicineList();
        // 获取药品性质
        this.getMedicineProperty();
        // 获取药品分类
        this.getMedicineType();
    },
    methods: {
        calcHeight() {
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 51;
            let guHeight = null;
            guHeight = 20 + 20 + 40 + 75;
            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
        },
        handleSelect(selectItem) {
            this.medicineForm.medicineName = selectItem.medicineName || '';
            this.medicineForm.medicineProperty = selectItem.medicineProperty || '';
            this.medicineForm.approvalNumber = selectItem.approvalNumber || '';
            this.medicineForm.manufacturer = selectItem.manufacturer || '';
            this.medicineForm.medicineType = selectItem.medicineType || '';
            this.medicineForm.specification = selectItem.specification || '';
            this.medicineForm.price = selectItem.price || '';
        },
        changeSelect() {
            this.medicineForm = { ...this.medicineForm };
        },
        getMedicineList(current) {
            if (current) {
                this.navForm.pageNo = 1;
            }

            this.instance.post('/tpa/api/medicine/paging', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicineList = data.data.list;
                    this.navForm.total = data.data.total;
                }
            });
        },
        editMedicine(itemRow, titleStr) {
            this.dialogTitle = titleStr == 'add' ? '添加药品' : '编辑药品';
            if (itemRow) {
                this.medicineForm = JSON.parse(JSON.stringify(itemRow));
            } else {
                this.medicineForm = {
                    mall: '',
                    price: '',
                    shopName: '',
                    goodsCategoryId: '',
                    goodsName: ''
                };
                this.$refs.commodityRef && this.$refs.commodityRef.resetFields();
            }
            this.goodsVisible = true;
        },
        updateMedicine(itemRow, flag) {
            let updateData = JSON.parse(JSON.stringify(itemRow));
            updateData.isCalibration = flag;
            this.instance.post('/tpa/api/medicine/warehousing', updateData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.resetForm();
                    this.getMedicineList();
                    this.$message.success('操作成功!');
                }
            });
        },
        resetForm() {
            this.goodsVisible = false;
            this.$refs.commodityRef && this.$refs.commodityRef.resetFields();
        },
        addDrusList(addFlag) {
            this.$refs.commodityRef.validate((valid) => {
                if (valid) {
                    if (addFlag) {
                        this.instance.post('/tpa/api/medicine/edit', this.medicineForm).then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.resetForm();
                                this.getMedicineList();
                                this.$message.success('操作成功！');
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                    } else if (this.medicineForm.isCalibration) {
                        this.instance.post('/tpa/api/medicine/add', this.medicineForm).then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.getMedicineList();
                                this.resetForm();
                                this.$message.success('药品添加成功！');
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                    } else {
                        this.medicineForm.isCalibration = true;
                        this.instance.post('/tpa/api/medicine/add', this.medicineForm).then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.resetForm();
                                this.$message.success('药品添加成功！');
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                    }
                }
            });
        },
        getMedicineProperty() {
            this.medicinePropertyOptions = [];
            this.instance.get('/tpa/api/enum/getMedicinePropertyEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicinePropertyOptions = data.data;
                }
            });
        },
        getMedicineType() {
            this.medicineTypeOptions = [];
            this.instance.get('/tpa/api/enum/getMedicineTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicineTypeOptions = data.data;
                }
            });
        },
        querySearch(queryString, cb) {
            let queryList = {
                condition: {
                    isCalibration: 1,
                    name: queryString
                },
                pageNo: 1,
                pageSize: 999
            };
            let results = [];
            this.instance.post('/tpa/api/medicine/paging', queryList).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    results = data.data.list;
                    results.forEach((res) => {
                        res.value = res.medicineName;
                    });
                } else {
                    results = [];
                }
                cb(results);
            });
        },
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getMedicineList();
        },
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getMedicineList();
        },
        //第一种方案：用户输入01、02等，则展示成1、2，等于非小数时过滤第一位0
        limitNum(amount) {
            const regex = /^\d+(\.\d{0,2})?$/;
            // 如果输入不符合正则表达式
            if (!regex.test(amount)) {
                const match = amount.match(/\d+(\.\d{0,2})?/);
                if (match) {
                    amount = match[0];
                } else {
                    amount = '';
                }
            }
            return amount;
        }
    }
};
