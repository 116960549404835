
import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
import Claimant from './Claimant.vue';
import ReportCase from './ReportCase.vue';
import CaseDetail from './CaseInfo.vue';
import CaseRisk from './CaseRisk.vue';
import GuaranteeSlip from './GuaranteeSlip.vue';
import Waybill from './Waybill.vue';
import CaseLog from './CaseLog.vue';
import JdPay from './JdPay.vue';
import CloseCase from './CloseCase.vue';
import LossAssessment from './LossAssessment.vue';
import CaseStep from './CaseStep.vue';
import SuspendNav from './suspendNav.vue';
import SurveyCase from './surveyCase.vue';
export default {
    name: 'myTask',
    props: {
        handleData: {
            type: Object,
            default: {}
        }
    },
    components: {
        ImgDeal,
        Claimant,
        ReportCase,
        CaseDetail,
        CaseRisk,
        GuaranteeSlip,
        Waybill,
        CaseLog,
        JdPay,
        CloseCase,
        LossAssessment,
        CaseStep,
        SuspendNav,
        SurveyCase
    },
    data() {
        return {
            scrollFlag: false,
            previewUrl: null,
            hideCaseLog: false,
            caseDetail: {
                caseRiskVos: []
            },
            allPriceVisible: false,
            drawer: false,
            caseLoading: false,
            checkedCase: {},
            caseDetail: {},
            goodsModeForm: {
                processMethod: 10,
                otherExpenses: []
            },
            damagedProcessMethod: [],
            damagedDegreeOption: [],
            suspensionBtnObj: {
                sort: 1,
                step: '立案',
                tmpStep: {}
            },
            submitDialogVisible: false,
            submitRuleForm: {
                action: 'APPROVE',
                reason: [],
                remark: '',
                supplyFileType: [],
                derogationReasonList: [],
                impairmentData: {
                    code: null,
                    remark: null
                }
            },
            radioGroupList: [
                {
                    value: 'APPROVE',
                    label: '通过'
                },
                {
                    value: 'REJECT',
                    label: '不通过'
                }
            ],
            reasonOption: [],
            caseZeroClaimEnum: [],
            caseCancelReasonEnum: [],
            getDictOption: [],
            reasonOptionList: [],
            isBtnShow: false,
            previewFlag: false,
            submitDisabled: true,
            disappearFlag: false,
            tmpOSSClient: null,
            detailHeight: '300px',
            suspensionList: [],
            tmpresponse: null,
            requestSum: 0,
            resetOssNum: 0,
            impairmentVisible: false,
            impairmentData: {
                amount: '',
                code: ''
            },
            impairmentReasonOption: [],
            impairmentData: {
                caseLossAmount: null,
                caseLossFormula: null
            },
            queryCaseLoss: {
                caseNo: '',
                calculateMethod: ''
            },
            caseProcessMethod: '',
            impairmentReasonOptionObj: {},
            reasonOptionList: [],
            caseMaterialsType: [], //材料类型
            detailsRadio: '0',
            detailsRadioArr: [
                {
                    label: '案件定损'
                },
                {
                    label: '京东谈赔信息'
                },
                {
                    label: '结案信息'
                },
                {
                    label: '案件信息'
                },
                {
                    label: '保单信息'
                },
                {
                    label: '运单信息'
                }
                // {
                //     label: '索赔信息'
                // },
                // {
                //     label: '案件风险'
                // },
                // {
                //     label: '报案信息'
                // },
            ]
        };
    },
    computed: {
        submitRules() {
            if (this.submitRuleForm.action == 'APPROVE_ZERO' || this.submitRuleForm.action == 'CANCEL') {
                return {
                    reason: [{ required: true, message: '请输入审核原因', trigger: ['blur', 'change'] }]
                };
            } else if (this.submitRuleForm.action == 'REJECT_PREV_STEP') {
                return {};
            } else {
                return {
                    'impairmentData.code': [{ required: true, message: '请选择减损类型', trigger: 'change' }]
                };
            }
        }
    },
    mounted() {
        this.initOSSClient();
        this.isBtnShow = this.handleData.detailShow != 'detailShow';
        window.onresize = () => {
            this.calcHeight();
        };
        // 绑定卡片tab 与 卡片 关联关系
        this.detailsRadioArr.forEach((item, index) => {
            item.code = index;
        });
        // 获取其他费用选项
        this.getOtherSelect();
        // 获取损失类型
        this.getDamagedDegreeEnum();
        // 获取材料类型
        this.getFileTypeList();
        this.getImpairmentRenson();
        // 获取审核原因
        this.getReason();
        this.getCaseCancelReasonEnum();
        this.getCaseZeroClaimEnum();
    },
    methods: {
        caseCardParentVariable(data) {
            this[data.variableStr] = data.flag;
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        handleScroll(e) {
            this.scrollFlag = e.target.scrollTop > 1;
        },
        surveyBtn(btnStr) {
            switch (btnStr) {
                case 'edit':
                    // 显示子组件，反馈查看信息弹窗
                    this.$refs.surveyCase.changeDialogFlag('surveyDialog', true);

                    break;
                case 'over':
                    // 传递查勘信息先校验必填项
                    this.$refs.surveyCase.submitFeeBack(false);
                    break;
                case 'log':
                    // 更新案件日志
                    this.handleCaseList(this.caseDetail.caseNo);
                    break;

                default:
                    // 查看信息校验通过，弹窗确认再提交查勘实际日期等
                    this.$confirm('查勘结束将会流转案件, 是否继续?', null, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            this.$refs.surveyCase.submitFeeBack(true);
                        })
                        .catch(() => {});
                    break;
            }
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        console.log('oss初始化成功');
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        previewPdf(preview) {
            if (preview) {
                this.previewFlag = true;
                this.submitDialogVisible = false;
            } else {
                // 关闭预览后，可以点击核赔确定按钮
                this.submitDisabled = false;
                this.submitDialogVisible = true;
                this.previewFlag = false;
            }
        },
        hiddenCaseLog() {
            this.hideCaseLog = !this.hideCaseLog;
        },
        saveForm() {
            localStorage.setItem(String(this.caseDetail.caseNo), JSON.stringify(this.submitRuleForm));
        },
        calcHeight() {
            let headerHeight = document.querySelector('.header') ? document.querySelector('.header').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let caseStepHeight = document.querySelector('.case-step') ? document.querySelector('.case-step').clientHeight : 0;
            let guHeight = 55 - 5 + 65;
            this.detailHeight = window.innerHeight - headerHeight - registerTabsHeight - caseStepHeight - guHeight + 'px';
        },
        closeCaseDetail(data) {
            this.$emit('closeCaseDetail', data);
        },
        handleRadioGroup(data) {
            this.suspensionBtnObj = data ? data : {};
            if (data && data.step == '核赔') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    },
                    {
                        value: 'REJECT_PREV_STEP',
                        label: '驳回上一步'
                    },
                    {
                        value: 'APPROVE_ZERO',
                        label: '0赔'
                    },
                    {
                        value: 'CANCEL',
                        label: '注销'
                    }
                ];
            }

            if (data && data.step == '定损') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    },
                    {
                        value: 'REJECT',
                        label: '补充材料'
                    }
                ];
            }
        },
        //获取案件录入信息
        getCaseEnter() {
            this.instance.get('/tpa/api/case/enter/adjust/getCaseEnter', { params: { caseNo: this.caseDetail.caseNo } }).then((res) => {
                if (res.data.code == 200) {
                    this.caseEnterData = res.data.data || {};
                    if (this.caseEnterData.caseNo) {
                        this.drawer = true;
                        this.goodsModeForm.compensationUnitPrice = this.caseEnterData.compensationUnitPrice;
                        this.goodsModeForm.processMethod = this.caseEnterData.processMethod;
                        this.calculateData();
                        this.goodsModeForm.damagedNum = this.caseEnterData.damagedNum;
                        this.goodsModeForm.damagedExtent = this.caseEnterData.damagedExtent;
                        this.goodsModeForm.deductibleRatio = this.caseEnterData.deductibleRatio;
                        this.goodsModeForm.damagedRatio = this.caseEnterData.damagedRatio;
                        this.goodsModeForm.accessoriesAmount = this.caseEnterData.accessoriesAmount;
                        this.goodsModeForm.repairAmount = this.caseEnterData.repairAmount;
                        this.goodsModeForm.otherExpenses = this.caseEnterData.otherExpenses;
                    }
                }
            });
        },
        // 定损计算
        lossCalculation() {
            let goodsTotalAmount = '1000';
            let detailAmount = '';
            let tmpGoodsDtoList = this.caseDetail.goodsDtoList;
            tmpGoodsDtoList &&
                tmpGoodsDtoList.forEach((item, index) => {
                    detailAmount += index != tmpGoodsDtoList.length - 1 ? item.adjustAmount + '+' : item.adjustAmount;
                });
            this.caseDetail.tmpTotalAmount = detailAmount.length > 0 ? detailAmount + ' = ' + goodsTotalAmount : '';
            this.$forceUpdate();
        },
        // 案件详情
        handleCaseList(caseNo) {
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            this.caseDetail = {};
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        this.initCaseDetail(res.data.data);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    console.log(res);
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            var _this = this;
            this.tmpresponse = JSON.parse(JSON.stringify(response));
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.submitRuleForm.caseNo = response.caseNo;
            this.queryCaseNo = response.caseNo;

            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
            }

            // 右侧悬浮窗
            if (response.closeCaseTime) {
                this.suspensionList = [{ name: '索赔信息' }, { name: '结案信息' }, { name: '运单信息' }, { name: '谈赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '报案信息' }, { name: '案件信息' }, { name: '质检结论' }, { name: '案件日志' }];
            } else {
                this.suspensionList = [{ name: '索赔信息' }, { name: '运单信息' }, { name: '谈赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '报案信息' }, { name: '案件信息' }, { name: '质检结论' }, { name: '案件日志' }];
            }

            // 结案报告
            if (response.reportPdf) {
                setTimeout(() => {
                    this.caseDetail.reportPdf = this.tmpOSSClient.signatureUrl(response.reportPdf);
                }, 1000);
            }

            // 是否需要现场勘查，展示查勘信息tab
            if (this.caseDetail.isSceneSurvey) {
                this.detailsRadioArr.unshift({ label: '查勘信息' });
            }
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                }
            });
        },
        editOtherList(index, btnStr) {
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: ''
                });
            }

            if (this.goodsModeForm.otherExpenses.length > 15) {
                this.$message.error('其他费用，目前仅支持添加15个费用');
            }
        },
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        //获取受损层度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        // 提交案件按钮
        submitCase(submitFlag) {
            let _this = this;
            if (!submitFlag) {
                this.submitDialogVisible = true;
                if (localStorage.getItem(String(this.caseDetail.caseNo))) {
                    this.submitRuleForm = JSON.parse(localStorage.getItem(String(this.caseDetail.caseNo)));
                }

                if (!this.submitRuleForm.impairmentData) {
                    this.submitRuleForm.impairmentData = {
                        code: null,
                        remark: null
                    };
                }
                this.radioInput(this.submitRuleForm.action);
            } else {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid && !this.disappearFlag) {
                        let tmpReason = null;
                        tmpReason = this.submitRuleForm.reason && this.submitRuleForm.reason.length > 0 ? [this.submitRuleForm.reason] : null;
                        this.submitRuleForm.reason = tmpReason;

                        // 减损计算
                        if (this.submitRuleForm.impairmentData && this.submitRuleForm.impairmentData.code.length > 0) {
                            var tmpObj = {
                                code: '',
                                desc: '',
                                remark: ''
                            };
                            this.submitRuleForm.derogationReasonList = [];
                            this.submitRuleForm.impairmentData.code.forEach((item, idnex) => {
                                tmpObj = {
                                    code: item,
                                    desc: this.impairmentReasonOptionObj[item],
                                    amount: this.impairmentData.caseLossAmount,
                                    caseLossFormula: this.impairmentData.caseLossFormula,
                                    caseLossAmount: this.impairmentData.caseLossAmount,
                                    remark: item == 70 ? this.submitRuleForm.impairmentData.remark : ''
                                };
                                this.submitRuleForm.derogationReasonList.push(tmpObj);
                            });
                        }
                        // 提交数据
                        this.submitRuleForm.caseNo = this.caseDetail.caseNo;
                        this.submitDisabled = true;
                        this.disappearFlag = true;

                        if (this.submitRuleForm.action == 'REJECT_PREV_STEP') {
                            this.submitRuleForm.reason = null;
                            this.submitRuleForm.impairmentData = null;
                            this.submitRuleForm.derogationReasonList = null;
                        } else {
                            this.submitRuleForm.supplyFileType = null;
                        }

                        if (!this.submitRuleForm.impairmentData) {
                            this.submitRuleForm.impairmentData = {
                                code: null,
                                remark: null
                            };
                        }
                        // 通过/驳回上一步
                        let interfaceStr = '/tpa/api/case/action';
                        // 0赔/注销
                        if (this.submitRuleForm.action == 'APPROVE_ZERO' || this.submitRuleForm.action == 'CANCEL') {
                            interfaceStr = '/tpa/api/case/zeroClaimOrCancel';
                        }
                        this.instance.post(interfaceStr, this.submitRuleForm).then((res) => {
                            this.submitDisabled = false;
                            if (res.data.code == 200) {
                                localStorage.removeItem(String(this.caseDetail.caseNo));
                                this.$message.success('提交成功');
                                setTimeout(() => {
                                    this.$emit('closeCaseDetail');
                                    this.disappearFlag = false;
                                    this.closeSubmitDialog();
                                }, 500);
                                this.handleCaseList(this.handleData.caseNo);
                            } else {
                                this.disappearFlag = false;
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            }
        },
        // 获取案件注销原因
        getCaseCancelReasonEnum() {
            this.instance.get('/tpa/api/enum/getCaseCancelReasonEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseCancelReasonEnum = data.data;
                }
            });
        },
        // 获取案件0赔付原因
        getCaseZeroClaimEnum() {
            this.instance.get('/tpa/api/enum/getCaseZeroClaimEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseZeroClaimEnum = data.data;
                }
            });
        },
        // 获取审核原因
        getReason() {
            this.instance.post('/tpa/api/enum/getDict', { dictName: this.handleData.menuTypeNum, dictType: 'ACTION_REASON_DICT' }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    data.data.forEach((item) => {
                        item.desc = item.metaData;
                        item.code = item.metaData;
                    });
                    this.getDictOption = data.data;
                }
            });
        },
        radioInput(activeVal) {
            this.disappearFlag = false;
            this.submitRuleForm.reason = null;
            switch (activeVal) {
                case 'APPROVE_ZERO':
                    this.reasonOption = this.caseZeroClaimEnum;
                    break;
                case 'CANCEL':
                    this.reasonOption = this.caseCancelReasonEnum;
                    break;
                case 'APPROVE':
                    this.reasonOption = this.getDictOption;
                    break;

                default:
                    break;
            }
        },
        closeSubmitDialog(saveFlag) {
            this.submitDisabled = true;
            this.submitDialogVisible = false;
            this.submitRuleForm.impairmentData = {
                code: null,
                remark: null
            };
            this.$refs.ruleForm.resetFields();
        },
        impairmentClick(removeFlag, index) {
            if (removeFlag) {
                this.submitRuleForm.derogationReasonList.splice(index, 1);
            } else {
                this.impairmentData = {
                    amount: null,
                    code: null,
                    desc: null
                };
                this.impairmentVisible = true;
            }
        },
        changeReason() {},
        getImpairmentRenson() {
            this.instance.post('/tpa/api/enum/getAdjustEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.impairmentReasonOption = data.data;
                    this.impairmentReasonOptionObj = {};
                    data.data.forEach((item) => {
                        this.impairmentReasonOptionObj[item.code] = item.desc;
                    });
                }
            });
        },
        impairmentConfirm(addFlag) {
            if (addFlag) {
                if (!this.impairmentData.amount) {
                    this.$message.error('减损金额不能为空！');
                    return false;
                }
                if (!this.impairmentData.code && this.impairmentData.code.length > 0) {
                    this.$message.error('减损原因不能为空！');
                    return false;
                }
                let tmpIndex = this.impairmentReasonOption.findIndex((item) => {
                    return item.code == this.impairmentData.code;
                });
                if (tmpIndex >= 0) {
                    this.impairmentData.desc = tmpIndex >= 0 ? this.impairmentReasonOption[tmpIndex].desc : '';
                    this.submitRuleForm.derogationReasonList.push(this.impairmentData);
                }
            }

            this.impairmentVisible = false;
        },
        getCaseLoss() {
            this.instance.get(`/tpa/api/case/enter/adjust/getCaseLoss?caseNo=${this.queryCaseLoss.caseNo}&calculateMethod=${this.queryCaseLoss.calculateMethod}`).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.impairmentData = data.data;
                    this.queryCaseLoss.calculateMethod = data.data.caseLossFormula.indexOf('= 索赔金额') != -1 ? '' : 'loss';
                    this.$forceUpdate();
                }
            });
        },
        resetCount(calculateMethod) {
            this.queryCaseLoss.calculateMethod = calculateMethod == '索赔金额' ? '' : 'loss';
            this.getCaseLoss();
        },
        getAdjust() {
            this.instance.get('/tpa/api/case/enter/adjust/getAdjust?caseNo=' + this.queryCaseLoss.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseProcessMethod = data.data.processMethod;
                }
            });
        }
    },
    watch: {
        handleData: {
            handler(val) {
                val.caseNo && this.handleCaseList(this.handleData.caseNo);
                // 获取减损计算公式
                if (val.caseNo) {
                    this.queryCaseLoss.caseNo = this.handleData.caseNo;
                    this.getCaseLoss();
                    this.getAdjust();
                }
                if (val.clickType == 'survey') {
                    this.surveyEdit = true;
                    this.detailsRadioArr.unshift({ label: '查勘信息' });
                }

                this.calcHeight();
            },
            immediate: true
        }
    }
};
