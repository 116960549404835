
import * as Options from "@/views/rule-engine/rule-admin/options";

export default {
  name: "ExpressionBuilder",
  props: {
    query: Object,
    parent: Object
  },
  data() {
    return {
      isVisible:true,
      autoTop:false,
      logicOperator: [],
      features: [],
      arithmeticOperator: [],
      otherLogicOperator: [],
    }
  },
  methods: {
    handleSubmit(){
      console.log(this.query)
    },
    changeLogicOperator: function (item) {
      // console.log(item)
      item.typeName = this.logicOperator.find((logic, index) => item.type === logic.code).desc;
      item.symbol = this.logicOperator.find((logic, index) => item.type === logic.code).sqlSymbol;
    },
    featureOnChange: function (item) {
      item.fieldName = this.features.find((feature, index) => feature.field === item.field).label;
    },
    featureValueOnChange: function (item) {
      item.termName = this.features.find((feature, index) => feature.field === item.term).label;
    },
    childrenValueOnChange: function (item) {
      item.children.termName = this.features.find((feature, index) => feature.field === item.children.term).label;
    },
    operatorChange (item) {
      let operator = this.arithmeticOperator.find((operator, index) => operator.code === item.operator);
      if (operator) {
        item.operatorDesc = operator.desc;
        item.symbol = operator.sqlSymbol;
      } else {
        item.operatorDesc = this.otherLogicOperator.find((operator, index) => operator.code === item.operator).desc;
        item.symbol = this.otherLogicOperator.find((operator, index) => operator.code === item.operator).sqlSymbol;
      }
      if(item.operator==220||item.operator==230||item.operator==240||item.operator==250){
        this.isVisible=false
      }
    },
    childrenOperatorChange: function (item) {
      let operator = this.arithmeticOperator.find((operator, index) => operator.code === item.children.operator);
      if (operator) {
        item.children.operatorDesc = operator.desc;
        item.children.symbol = operator.sqlSymbol;
      } else {
        item.children.operatorDesc = this.otherLogicOperator.find((operator, index) => operator.code === item.children.operator).desc;
        item.children.symbol = this.otherLogicOperator.find((operator, index) => operator.code === item.children.operator).sqlSymbol;
      }
    },
    addNode: function() {
      this.query.children.push({
        field: "",
        fieldName: "",
        operator: "",
        operatorDesc: "",
        symbol: "",
        term: "",
        termName: "",
        constant: "",
        children: {
          operator: "",
          operatorDesc: "",
          symbol: "",
          term: "",
          termName: "",
          constant: "",
        },
      });
      this.getLocation();
    },
    addGroup: function() {
      this.query.children.push({
        group: true,
        type: "",
        typeName: "",
        symbol: "",
        children: [
          {
            field: "",
            fieldName: "",
            operator: "",
            operatorDesc: "",
            symbol: "",
            term: "",
            termName: "",
            constant: "",
            children: {
              operator: "",
              operatorDesc: "",
              symbol: "",
              term: "",
              termName: "",
              constant: "",
            },
          }
        ]
      });
      this.getLocation();
    },
    getLocation(){
      // console.log()
      let len=this.query.children.length;
      if(this.query.children[len-1].group){
        this.autoTop=true;
      }else{
        this.autoTop=false;
      }
    },
    removeItem(item) {
      let index = this.query.children.indexOf(item);
      this.query.children.splice(index, 1);
    },

    removeGroup(res) {
      console.log("res" + res)
      let index = this.query.children.indexOf(res);
      this.parent.children.splice(index, 1);
    },

    getLogicOperatorEnum () {
      this.loading = true;
      this.instance
          .get("/risk/api/enum/getLogicOperatorEnum")
          .then(({data}) => {
            this.loading = false;
            this.logicOperator = data.data;
          }).catch(_ => this.loading = false)
    },
    getFeatureList () {
      this.instance
          .post("/risk/api/feature/definition/paging", {pageNo: 1, pageSize: 100})
          .then(({data}) => {
            const list = data.data.list;
            const columns = [];
            const values = list.map(item => item.featureName);
            for (var i = 0; i < list.length; i++) {
              const feature = list[i];
              columns.push({
                field: feature.featureCode,
                label: feature.featureName,
                type: Options.getDataType(feature.dataType),
                //template: this.paymentTemplate,
                //values: values.filter(v => v != feature.featureName)
              })
            }
            this.features = columns;
            //this.$refs.querybuilder.ej2Instances.setRules(this.importRules);
          });
    },
    getArithmeticOperator () {
      this.loading = true;
      this.instance
          .get("/risk/api/enum/getArithmeticOperatorEnum")
          .then(({data}) => {
            this.loading = false;
            this.arithmeticOperator = data.data;
          }).catch(_ => this.loading = false)
    },
    getOtherLogicOperator () {
      this.loading = true;
      this.instance
          .get("/risk/api/enum/getOtherLogicOperatorEnum")
          .then(({data}) => {
            this.loading = false;
            this.otherLogicOperator = data.data;
          }).catch(_ => this.loading = false)
    }
  },
  mounted() {  //
    this.getLogicOperatorEnum();
    this.getFeatureList();
    this.getArithmeticOperator();
    this.getOtherLogicOperator();
  }
};
