
import ExpressionBuilder from './components/ExpressionBuilder'

export default {
    name: 'App',
    props: {
        expression: Object
    },
    data: function () {
        return {
            query: {
                type: '',
                typeName: '',
                symbol: '',
                children: [
                    {
                        field: '',
                        fieldName: '',
                        operator: '',
                        operatorDesc: '',
                        symbol: '',
                        term: '',
                        termName: '',
                        constant: '',
                        children: {
                            operator: '',
                            operatorDesc: '',
                            symbol: '',
                            term: '',
                            termName: '',
                            constant: ''
                        }
                    }
                ]
            },
            sql: '',
            sqlShow: ''
        }
    },
    components: {
        ExpressionBuilder
    },
    methods: {
        getSql: function (query) {
            this.$data.sql = this.queryToSql(query)
            this.$data.sqlShow = this.queryToSqlShow(query)
        },

        queryToSql: function (query) {
            let sql = []
            let that = this
            let logicalOperator = query.symbol
            let children = query.children
            children.forEach((child) => {
                if (child.group) {
                    sql.push('(')
                    sql.push(that.queryToSql(child))
                    sql.push(')')
                } else {
                    if (child.children && child.children.operator !== '') {
                        sql.push('(')
                        sql.push(child.field)
                        sql.push(child.symbol)
                        if (child.constant) {
                            sql.push(child.constant)
                        } else {
                            sql.push(child.term)
                        }
                        sql.push(')')
                        sql.push(child.children.symbol)
                        if (child.children.constant) {
                            sql.push(child.children.constant)
                        } else {
                            sql.push(child.children.term)
                        }
                    } else {
                        sql.push(child.field)
                        sql.push(child.symbol)
                        if (child.constant) {
                            sql.push(child.constant)
                        } else {
                            sql.push(child.term)
                        }
                    }
                }
                sql.push(logicalOperator)
            })
            sql.splice(sql.length - 1, sql.length)
            return sql.join(' ')
        },
        queryToSqlShow: function (query) {
            let sqlShow = []
            let that = this
            let logicalOperatorName = query.typeName
            let children = query.children
            children.forEach((child) => {
                if (child.group) {
                    sqlShow.push('(')
                    sqlShow.push(that.queryToSqlShow(child))
                    sqlShow.push(')')
                } else {
                    if (child.children && child.children.operator !== '') {
                        sqlShow.push('(')
                        sqlShow.push(child.fieldName)
                        sqlShow.push(child.operatorDesc)
                        if (child.constant) {
                            sqlShow.push(child.constant)
                        } else {
                            sqlShow.push(child.termName)
                        }
                        sqlShow.push(')')
                        sqlShow.push(child.children.operatorDesc)
                        if (child.children.constant) {
                            sqlShow.push(child.children.constant)
                        } else {
                            sqlShow.push(child.children.termName)
                        }
                    } else {
                        sqlShow.push(child.fieldName)
                        sqlShow.push(child.operatorDesc)
                        if (child.constant) {
                            sqlShow.push(child.constant)
                        } else {
                            sqlShow.push(child.termName)
                        }
                    }
                }
                sqlShow.push(logicalOperatorName)
            })
            sqlShow.splice(sqlShow.length - 1, sqlShow.length)
            return sqlShow.join(' ')
        }
    }
}
